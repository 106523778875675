import React, { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface SectionsProps {
	children: ReactNode,
	className?: string;
	title?: string,
	icon?: React.ReactElement,
	actionIcon?: React.ReactElement,
}

const Section = (props: SectionsProps) => {
	const {
		children,
		className,
		title,
		icon,
		actionIcon,
	} = props;

	const isHeaderLess = !title;

	const containerClasses = classnames(
		{
			[`${classPrefix}-section-container`]: true,
			[`${classPrefix}-section-container--header-less`]: isHeaderLess
		},
		className
	)

	return (
    <section className={containerClasses}>
      {!isHeaderLess && (
        <div className={`${classPrefix}-section-header`}>
          <div className={'d-flex align-items-center'}>
            {icon && (
              <div className={`${classPrefix}-section-header-icon`}>{icon}</div>
            )}
            <div className={`${classPrefix}-section-header-title`}>{title}</div>
          </div>

          <div className={`${classPrefix}-section-header-action-icon`}>
            {actionIcon}
          </div>
        </div>
      )}
      <div className={`${classPrefix}-section-body`}>{children}</div>
    </section>
  );
}

export default Section;