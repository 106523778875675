import { PaginationProps } from 'antd';
import marker from 'Assets/icon/marker.svg';
import plus from 'Assets/icon/plus.svg';
import search from 'Assets/icon/search.svg';
import ButtonClick from 'Components/ButtonClick';
import InputText from 'Components/InputText';
import Paginate from 'Components/Paginate';
import Section from 'Components/Section';
import { debounce } from 'lodash';
import { AddressList } from 'Pages/AddressBook/AddressListing/components';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DTOAddress } from 'Services/v1/address-book/dto';
import * as addressBook from 'Stores/address-book';
import flightSlice from 'Stores/flights';
import { getResetLocation } from 'Stores/locations';
import {
  orderBillSelectors,
  updateReceiverAddress,
  updateSenderAddress
} from 'Stores/order-bill';
import { PAGESIZE, PAGESIZE_UNLIMIT, VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { AddressPathEnum, MainPageEnum } from '../../../routes/enums';

import { useLoadingOverlay } from 'Components/LayoutUtilities';
import './index.scss';

interface AddressListingProps {
	isSelectable?: boolean;
}

interface SelectAddressStoreMapping {
	[key: string]: {
		updateAction: any
		fieldName: string
		cleanUp: () => void
	}
}

const AddressListing = (props: AddressListingProps) => {
	const { isSelectable = false } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const addressListing = useSelector(addressBook.addressBookSelectors.get).list;
	const fetchingStatus = useSelector(addressBook.addressBookSelectors.get).fetching;
	const addressMeta = useSelector(addressBook.addressBookSelectors.get).meta;
	const urlNavigationReturn = useSelector(addressBook.addressBookSelectors.get).urlNavigationReturn;
	const orderBillState = useSelector(orderBillSelectors.get);
	const accessibleLocation = useSelector(addressBook.addressBookSelectors.get).accessibleLocation;
	const accessibleLocationIds = accessibleLocation ? Object.keys(accessibleLocation) : [];
	const [searchParams] = useSearchParams();
	const page = parseInt(searchParams.get('page') || '1');
	const {orderType, addressType} = useParams();
	const {startLoadingOverlay, stopLoadingOverlay} = useLoadingOverlay();
	const isC2C = orderType === 'c2c';
  const limit = isC2C ? PAGESIZE_UNLIMIT : PAGESIZE;

	const storeMapping:SelectAddressStoreMapping = {
		sender: {
			updateAction: updateSenderAddress,
			fieldName: 'senderAddress',
			cleanUp: () => {
				dispatch(flightSlice.actions.departureAirportsActions.reset())
				dispatch(flightSlice.actions.arrivalAirportsActions.reset())
			}
		},
		receiver: {
			updateAction: updateReceiverAddress,
			fieldName: 'receiverAddress',
			cleanUp: () => {
				dispatch(flightSlice.actions.arrivalAirportsActions.reset())
			}
		},
	} as any;

	// @ts-ignore
	const selectedAddress = isSelectable ? orderBillState[storeMapping[addressType].fieldName] : undefined;
	const nextSearchParams = useMemo(() => {
		if(!addressType || !orderType) return createSearchParams({});
		return createSearchParams({
			addressType,
			orderType,
			page: page.toString()
		})
	}, [addressType, orderType, page])

	const onHandleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		const key = event.target.value.toLowerCase();
		setKeyword(event.target.value);
		setIsSearching(true);
		debounceValue(key);
	};

	const debounceValue = useCallback(
		debounce((nextValue) => fetchValue(nextValue), 500),
		[addressListing],
	);

	const fetchValue = (search_text: string) => {
		dispatch(addressBook.getAddressBook({ limit, page: 1, search_text }));
		setIsSearching(false);
	};

	useEffect(() => {
		if (isC2C && addressType === 'sender') {
			dispatch(addressBook.getAccessibleLocation())
		}
		dispatch(getResetLocation());
	}, [isC2C, addressType]);

	const goToCreatePage = () => {
		navigate({
			pathname: AddressPathEnum.AddressFormCreate,
			search: nextSearchParams.toString()
		});
	}

	const onEditAddress = (item: DTOAddress) => {
		dispatch(addressBook.getAddressBookDetail(item));
		navigate({
			pathname: AddressPathEnum.AddressFormUpdate.replace(':id', item.id),
			search: nextSearchParams.toString(),
		});
	};

	const saveAddressToStore = (item: DTOAddress) => {
		const storeData = storeMapping[
			addressType as unknown as keyof typeof storeMapping
		]
		dispatch(storeData.updateAction(item));
		storeData.cleanUp();
	}

	// const cargoSelectAddress = (item: DTOAddress) => {
	// 	saveAddressToStore(item);
	// 	navigate(MainPageEnum.OrderBill);
	// }

	useEffect(() => {
		if(fetchingStatus.listingStatus === 'pending') {
			startLoadingOverlay();
			return () => stopLoadingOverlay();
		}
		stopLoadingOverlay();
	}, [fetchingStatus])

	const onSelectAddress = (item: DTOAddress) => {
		saveAddressToStore(item);
		if(isC2C) {
			return navigate(`/${MainPageEnum.C2cOrderPayment}`);
		}

		navigate(`/${MainPageEnum.OrderBill}`);
	}

	const renderData = () => {
		if (isC2C) {
			if(addressType === 'receiver') return <AddressList isSelectable={isSelectable} onEdit={onEditAddress} selectedItem={selectedAddress} onSelect={onSelectAddress} data={addressListing}/>
			const validAddresses = addressListing.filter(address => accessibleLocationIds.includes(address.province) || address.country?.toString() !== VIETNAM_COUNTRY_CODE)
			const invalidAddresses = addressListing.filter(address => !accessibleLocationIds.includes(address.province) && address.country?.toString() === VIETNAM_COUNTRY_CODE)

			return <>
				<AddressList isSelectable={isSelectable} onEdit={onEditAddress} selectedItem={selectedAddress} onSelect={onSelectAddress} data={validAddresses}/>
				<AddressList onEdit={onEditAddress} disabled={true} data={invalidAddresses} title={'message_do_not_support_shipping'} subTitle={'message_do_not_support_shipping_by_location'}/>
			</>
		} else {
			return <AddressList isSelectable={isSelectable} onEdit={onEditAddress} selectedItem={selectedAddress} onSelect={onSelectAddress} data={addressListing}/>
		}
	};

	const renderPagination = () => {
		if (addressMeta.pagination && addressMeta.pagination.total) {
			return (
				<Paginate
					total={addressMeta.pagination.total}
					size={addressMeta.pagination.perPage}
					current={page}
					onPageChange={onPageChange}
				/>
			);
		}
	};

	useEffect(() => {
		dispatch(addressBook.getAddressBook({ limit, page: isC2C ? 1 : page }))
	}, [page, isC2C])

	const onPageChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
		navigate(`?page=${pageNumber}`);
	};

	const compClass = `${classPrefix}-address-listing`;

	return (
		<>
			<Section
				title={t('title_saved_address')}
				icon={<img src={marker} width="14" alt={''} />}
			>
				<div className={`${compClass}-container pb-4`}>
					<div
						className={`${compClass}-address-search-bar d-flex mt-4 justify-content-around`}
					>
						<InputText
							className={`${classPrefix}-address-search-input`}
							value={keyword}
							name="search-box"
							placeholder={t('placeholder_search_address')}
							onChange={onHandleSearch}
							addonBefore={<img src={search} width="18" />}
							onKeyUp={() => onHandleSearch}
						/>
					</div>
					<div className="add-address pt-10 px-4">
						<div className={`${classPrefix}-btn-container`}>
							<ButtonClick
								variant="default"
								icon={<img src={plus} width="14" className="mr-2" />}
								onClick={goToCreatePage}
							>
								{t('buttons_add_address')}
							</ButtonClick>
						</div>
						<div>{renderData()}</div>
					</div>
				</div>
				<Outlet />
			</Section>
			{ !isC2C && renderPagination() }
		</>
	);
};

export default AddressListing;
