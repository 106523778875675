import { Card, Checkbox } from 'antd';
import PackageIcon from 'Assets/icon/package-icon.svg';
import PageCallCenterService from 'Components/PageCallCenterService/pageCallCenterService';
import { isArray } from 'lodash';
import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { DTOOrder, DTOProductOrder } from 'Services/v1/cargo-direct/dto';
import { classPrefix } from 'Utilities/global';
import './index.scss';
import { BOOKING_TYPE } from 'Utilities/constants';
import { displayCurrency } from 'Utilities/util';


const currency = (orderDetails: DTOProductOrder[]) => orderDetails?.every((x) => x.price) ? ' (USD)' : '';


interface ProductHeaderProps {
	order: DTOOrder
}

interface ProductItemProps {
	item: DTOProductOrder
	order: DTOOrder
}

const CargoProductItem = (props: ProductItemProps) => {
	const {item, order} = props;
	const { t } = useTranslation();
	const dimension = JSON.parse(order.dimension || '{}');
	const declaredWeight = dimension.declared_weight / 1000;
	const actualWeight = dimension.weight / 1000;
	const declaredDimension = dimension.dimension || [];
	const {currency, amount} = order;

	return (
		<>
			<div className={'text-start'}>
				<img
					src={PackageIcon}
					width="18"
					className="me-3"
				/>
				<span>
					{t(getProductName(item, order.packageType))}
				</span>
			</div>
			<div>{declaredWeight} kg</div>

			<div>{declaredDimension.map((d) => {
				return <div>{d.long} x {d.width} x {d.height} x {d.quantity}</div>
			})}</div>
			<div>{actualWeight} kg</div>
			<div>{order.quantity}</div>
			<div>
				{displayCurrency(amount, currency)}
			</div>
		</>
	)
}

const CargoProductHeader = (props: ProductHeaderProps) => {
	const {order} = props;
	const { t } = useTranslation();

	return (
		<>
			<div>{t('cargo_detail_declaration_table_header_weight')}</div>
			<div>{t('cargo_detail_declaration_table_header_dimension')}</div>
			<div>{t('cargo_detail_declaration_table_header_charge_weight')}</div>
			<div>{t('cargo_detail_declaration_table_header_package_quantity')}</div>
			<div>{t('cargo_detail_declaration_table_header_total')}</div>
		</>
	)
}

const getProductName = (item: DTOProductOrder, packageType?: string) => {
	if (
		item?.productName &&
		packageType === C2cCategoryPackageTypeEnum.Parcel
	) {
		return item?.productName;
	} else {
		// Multi language for VN product
		return `commodity_${item.productionCode || item.key}`
	}
};

const C2CProductHeader = (props: ProductHeaderProps) => {
	const {order} = props;
	const { t } = useTranslation();

	return (
		<>
			<div>{t('global_unit_price') + currency(order.orderDetails)}</div>
			<div>{t('global_quantity')}</div>
			<div>{t('global_sub_total') + currency(order.orderDetails)}</div>
		</>
	)
}

const C2CProductItem = (props: ProductItemProps) => {
	const {item, order} = props;
	const { t } = useTranslation();

	return (
		<>
			<div className={'text-start'}>
				<img
					src={PackageIcon}
					width="18"
					className="me-3"
				/>
				<span>
					{t(getProductName(item, order.packageType))}
				</span>
			</div>
			<div>{item.price && parseFloat(item.price)}</div>
			<div>{item?.quantity}</div>
			<div>
				{item?.price &&
				item.quantity &&
				parseFloat(item?.price) *
				parseFloat(item.quantity)}
			</div>
		</>
	)
}


const ProductOrder = (props: DTOOrder) => {
	const { orderDetails, statusName, vatInfo, packageType, platform } = props;

	const { t } = useTranslation();

	const ProductItemComp = platform === BOOKING_TYPE.CargoDirect ? CargoProductItem : C2CProductItem;
	const ProductHeaderComp = platform === BOOKING_TYPE.CargoDirect ? CargoProductHeader : C2CProductHeader;

	const productItem = (productOrder: DTOProductOrder[]) => {
		return (
			<>
				{productOrder &&
					isArray(productOrder) &&
					productOrder.map((item: DTOProductOrder, key) => (
						<ProductItemComp item={item} order={props}/>
					))}
			</>
		);
	};
	return (
		<Card
			className={`${classPrefix}-product-container mb-4`}
			bordered={false}
		>
			<div className="product-body bg-white">
				<div className="table-product p-5">
					<div className={`table-product-header table-product-header--${platform === BOOKING_TYPE.CargoDirect ? 'cargo' : 'c2c'}`}>
						<div className="fw-500 text-start">{t('global_product')}</div>
						<ProductHeaderComp order={props}/>
					</div>
					<div className={`table-product-body table-product-body--${platform === BOOKING_TYPE.CargoDirect ? 'cargo' : 'c2c'}`}>
						{productItem(orderDetails)}
					</div>
				</div>
			</div>

			<div className="product-footer p-6">
				<div className="d-flex justify-content-between">
					{statusName === 'CANCELLED' && (
						<div>
							<Checkbox
								className={`${classPrefix}-checkbox-container`}
								checked={!!vatInfo}
								disabled
							>
								{t('order_detail_vat_checkbox_label')}
							</Checkbox>
						</div>
					)}
					<PageCallCenterService></PageCallCenterService>
				</div>
			</div>
		</Card>
  );
};

export default ProductOrder;
