import {
  combineReducers,
  createSlice, PayloadAction, Reducer
} from '@reduxjs/toolkit';
import { DTOAirportInfo, DTOGetAvailableFlightResponse } from 'Services/v1/flights/dto';
import {
  FlightsState, GetSuggestAirportPayload, SaveSuggestAirportPayload, SuggestedAirportsState
} from 'Stores/flights/models';
import higherOrderReducers from 'Stores/hors';
import { defaultGeneralInitialState, defaultListInitialState, ListStateActions } from 'Stores/hors/fetchedSlice';
import { RootState } from 'Stores/index';
import { GeneralDataState, ListItemsState } from 'Stores/models';

const parentStateName = 'flights'

const arrivalAirportsSlice = higherOrderReducers.createFetchedSlice<ListItemsState<any>, DTOAirportInfo, any>(`${parentStateName}/arrivalAirports`, defaultListInitialState);
const departureAirportsSlice = higherOrderReducers.createFetchedSlice<ListItemsState<any>, DTOAirportInfo, any>(`${parentStateName}/departureAirports`, defaultListInitialState);
const availableFlightsSlice = higherOrderReducers.createFetchedSlice<GeneralDataState<any>, DTOGetAvailableFlightResponse, any>(`${parentStateName}/availableFlights`, defaultGeneralInitialState)

const suggestedAirportInitialState: SuggestedAirportsState = {}

export const suggestedAirportsSlice = createSlice({
	name: `${parentStateName}/suggestedAirports`,
	initialState: suggestedAirportInitialState,
	reducers: {
		get: (state, action:PayloadAction<GetSuggestAirportPayload>) => {
		},

		save(state, action: PayloadAction<SaveSuggestAirportPayload>) {
			state[action.payload.provinceCode] = action.payload.data;
		}
	}
})

interface FlightSlice {
	reducer: Reducer<FlightsState>;
	actions: {
    arrivalAirportsActions: ListStateActions,
    departureAirportsActions: ListStateActions,
    availableFlightsActions: ListStateActions,
    suggestedAirportsActions: any,
  };
	selectors: any
}

const FlightSlice:FlightSlice = {
	reducer: combineReducers<FlightsState>({
		arrivalAirports: arrivalAirportsSlice.reducer,
		departureAirports: departureAirportsSlice.reducer,
		suggestedAirports: suggestedAirportsSlice.reducer,
		availableFlights: availableFlightsSlice.reducer
	}),
	actions: {
		arrivalAirportsActions: arrivalAirportsSlice.actions as ListStateActions,
		departureAirportsActions: departureAirportsSlice.actions as ListStateActions,
		availableFlightsActions: availableFlightsSlice.actions as ListStateActions,
		suggestedAirportsActions: suggestedAirportsSlice.actions
	},
	selectors: {
		get: (state: RootState) => (state.flights as FlightsState)
	}
}

export default FlightSlice;