import { InputNumber as AntdInputNumber } from 'antd';
import React from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';


export interface InputNumberProps {
	value?: number,
	onChange?: (value: number) => void,
	min?: number,
	max?: number,
	step?: number,
	defaultValue?: number,
	ref?: React.Ref<HTMLInputElement>,
	className?: string,
	formatter?:(value: number | undefined) => string,
  isOriginal?: boolean;
  onFocus?: (ev: any) => void;
  isSelectAll?: boolean;
}

const InputNumber = (props: InputNumberProps) => {
	const {
		className,
		step,
		value,
		max,
		min,
		defaultValue,
		onChange,
		formatter,
    isOriginal = false,
    onFocus,
    isSelectAll,
		ref
	} = props

  const handleFocus = (e: any) => {
    if (isSelectAll) {
      e.target.select();
    }
    if (onFocus) onFocus(e);
  }

	const containerClasses = classnames(
		{
			[`${classPrefix}-input-number-container`]: true,
			[`${classPrefix}-input-number-container--original`]: !isOriginal,
		},
		className
	)
	return <div className={containerClasses}>
		<AntdInputNumber
			value={value}
			ref={ref}
			step={step}
			min={min}
			max={max}
			defaultValue={defaultValue}
			onChange={onChange}
			formatter={formatter}
      onFocus={handleFocus}
		/>
	</div>;
};

export default InputNumber;