import { MessageAlertModel } from 'Components/Models';
import {
  C2cDeliveryMethod,
  C2cPromoCode,
  ProductClassification,
  ProductSizeAndWeight
} from 'Pages/C2cOrderPayment';
import { C2cPickedUpProduct } from 'Pages/C2cOrderPayment/components';
import TaxAndFeeComponent from 'Pages/C2cOrderPayment/components/taxAndFee';
import {
  C2cOrderTabs,
  C2cOrderTabsGlobal
} from 'Pages/C2cOrderPayment/constants';
import useGetPrice from 'Pages/C2cOrderPayment/containers/c2cOrderPaymentContainer/useGetPrice';
import {
  C2cCategoryPackageTypeEnum,
  C2cOverPaymentTextEnum
} from 'Pages/C2cOrderPayment/enums';
import C2cCheckValidationInputLogic from 'Pages/C2cOrderPayment/logics/c2c-check-validation-input.logic';
import C2CSelectAddress from 'Pages/C2cOrderPayment/SelectAddress';
import PageTitle from 'Pages/common/PageTitle';
import CargoCheckValidationInputLogic from 'Pages/OrderBill/logics/cargo-check-validation-input.logic';
import SelectPaymentMethod from 'Pages/OrderBill/SelectPaymentMethod';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainPageEnum } from 'Routes';
import {
  c2cOrderPaymentSelectors,
  checkValidationInputAction,
  checkValidationProductDeclaration,
  ExtraServiceQueryModel,
  getC2cProductTypesAction,
  getGetExtraService,
  InitC2cValidationValue,
  PaymentKorea,
  postC2cOrderAction,
  resetC2cOrderPayment,
  setIsFirstTimeInputC2C,
  setIsFirstTimeInputGlobal,
  setToggleOrder,
  updateOrderTypeAction
} from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import {
  layoutUtilitiesSliceSelectors,
  updateLoadingOverlay
} from 'Stores/layout-utilities';
import {
  orderBillSelectors,
  setCargoValidationInputByField
} from 'Stores/order-bill';
import { TypePayment } from 'Utilities/constants';
import { getConfirmationUrl } from 'Utilities/util';
import './styles.scss';

const C2cOrderPaymentContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { typeOrder } = useSelector(c2cOrderPaymentSelectors.get);
  const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
  const c2cState = useSelector(c2cOrderPaymentSelectors.get);
  const orderBillState = useSelector(orderBillSelectors.get);
  const { currentPath } = useSelector(layoutUtilitiesSliceSelectors.get);
  const productDeclaration = useSelector(
    c2cOrderPaymentSelectors.getAllProductDeclaration
  );

  const {
    weightAndSize,
    packageTypeSelected,
    productSelected,
    chosenPaymentMethod,
    deliveryMethodSelected,
		isFirstTimeInputC2C,
		isFirstTimeInputGlobal,
    supperFee,
		paymentConfig,
		isGlobal,
		globalDirection,
	  c2cValidateFields
  } = c2cState;
	const { paymentMethod, senderAddress, receiverAddress } = orderBillState;

  // TODO: Complex computation
  const [toggleSizeAndWeight, setToggleSizeAndWeight] = useState(true);
  const checkInputC2c = C2cCheckValidationInputLogic(
    c2cState,
    orderBillState,
    productDeclaration
  );

  useEffect(() => {
    dispatch(checkValidationInputAction({...c2cValidateFields ,...InitC2cValidationValue }));
  }, [packageTypeSelected]);

  useGetPrice();

  const deliveryMethodPrice = deliveryMethodSelected?.price || 0;
  const deliveryMethodDiscount = deliveryMethodSelected?.discount || 0;

  const finalPrice = +(
    deliveryMethodPrice -
    deliveryMethodDiscount +
    (supperFee || 0)
  );

  useEffect(() => {
		if(isGlobal && globalDirection === 'inbound') return;

    if (!weightAndSize?.weight) {
      return;
    }
    const payload = {
      shippingFee: finalPrice,
			weight: weightAndSize?.weight,
			locationFrom: senderAddress?.country,
			locationTo: receiverAddress?.country
    } as ExtraServiceQueryModel;

    dispatch(getGetExtraService(payload));
	}, [finalPrice, weightAndSize?.weight, senderAddress?.country, receiverAddress?.country, isGlobal, globalDirection]);

  useEffect(() => {
		if(!senderAddress?.country || !receiverAddress?.country) return;

		dispatch(getC2cProductTypesAction({
			lang: currentLanguage || 'en',
			locationFrom: senderAddress.country,
			locationTo: receiverAddress.country
		}));
	}, [currentLanguage, senderAddress?.country, receiverAddress?.country]);

  useEffect(() => {
    if (
      typeOrder !== TypeOrder.BASIC &&
			isGlobal
    ) {
			const checkDataInput = checkInputC2c.checkProductDeclaration();

			dispatch(checkValidationProductDeclaration(checkDataInput));
    }

    if (
      location?.pathname?.replace('/', '') === MainPageEnum.C2cOrderPayment &&
      currentPath === MainPageEnum.GoodsInfo &&
			isGlobal &&
			isFirstTimeInputGlobal
    ) {
			const checkDataInput = checkInputC2c.checkProductDeclaration();

			dispatch(checkValidationProductDeclaration(checkDataInput));
			dispatch(setIsFirstTimeInputGlobal(false));
    }

    if (
			isFirstTimeInputGlobal &&
      typeOrder === TypeOrder.BASIC &&
			isGlobal
    ) {
      dispatch(
        checkValidationProductDeclaration({
          hasError: false
        } as MessageAlertModel)
      );
    }
	}, [isFirstTimeInputGlobal, typeOrder]);

  useEffect(() => {
		if (!isFirstTimeInputC2C) {
      dispatch(
        checkValidationInputAction(checkInputC2c.checkC2cInput())
      );
    }
  }, [
    weightAndSize?.height,
    weightAndSize?.weight,
    weightAndSize?.longs,
    weightAndSize?.width,
    productSelected,
    paymentMethod,
    productDeclaration,
    chosenPaymentMethod
  ]);

  const handleOnSubmitOrder = () => {
    dispatch(setToggleOrder());
    const checkDataInput = checkInputC2c.checkC2cInput();
    dispatch(checkValidationInputAction(checkDataInput));
		dispatch(setIsFirstTimeInputC2C(false));

	  // Check sender and receiver address
	  const checkInputCargo = CargoCheckValidationInputLogic(orderBillState);
	  const checkDataCargoInput = checkInputCargo.checkCargoInput();
	  dispatch(
		  setCargoValidationInputByField({
			  key: 'senderAddress',
			  value: checkDataCargoInput.senderAddress
		  })
	  );
	  dispatch(
		  setCargoValidationInputByField({
			  key: 'receiverAddress',
			  value: checkDataCargoInput.receiverAddress
		  })
	  );


	  if (
      checkInputC2c.checkInValid() ||
      checkDataCargoInput.senderAddress?.hasError ||
      checkDataCargoInput.receiverAddress?.hasError
    )
      return;
    dispatch(updateLoadingOverlay(true));
    dispatch(
			postC2cOrderAction((isGlobal, data) => {
        dispatch(updateLoadingOverlay(false));
				if (isGlobal) {
          const paymentKorea = new PaymentKorea(data);
          if (!paymentKorea?.success) return;
          navigate(getConfirmationUrl(TypePayment.CASH, true));
        }
      })
    );
  };

  const handleOnChangeTab = (key: string) => {
    dispatch(resetC2cOrderPayment(false));
    dispatch(updateOrderTypeAction(key));
  };

  const checkShowHidePaymentMethod = () => {
    return (
      (deliveryMethodSelected?.avaiPaymentMethods &&
        deliveryMethodSelected?.avaiPaymentMethods.length > 0) ||
      (paymentConfig && paymentConfig.length > 0)
    );
  };

  return (
    <>
      <PageTitle
        title={t(C2cOverPaymentTextEnum.C2cOverPayment_Title)}
        tabs={
					isGlobal
						? C2cOrderTabsGlobal
            : C2cOrderTabs
        }
        defaultActiveKey={typeOrder}
        onChange={handleOnChangeTab}
      />
      <div className="mb-4 row">
        <div className="col-xl-8 col-lg-8 col-lg-12 pe-xl-0">
          <div className="mb-4">
            <C2CSelectAddress />
          </div>
          <div className="mb-4">
            <ProductClassification />
          </div>
          {packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel && (
            <div className="mb-4">
              <C2cPickedUpProduct />
            </div>
          )}

          <div className="mb-4">
            <ProductSizeAndWeight
              onAfterChange={() => setToggleSizeAndWeight(!toggleSizeAndWeight)}
            />
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-lg-12 row h-100 justify-content-end p-0 m-0">
          {!!c2cState.deliveryMethodSelected &&
            Object.keys(c2cState.deliveryMethodSelected).length > 0 && (
              <div className="mb-4 col-lg-12">
                <C2cDeliveryMethod />
              </div>
            )}
          <div className="mb-4 col-lg-6 col-md-6 col-xl-12">
            <C2cPromoCode />
          </div>
          {!!checkShowHidePaymentMethod() && (
            <div className="mb-4 col-lg-6 col-md-6 col-xl-12">
              <SelectPaymentMethod />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-xl-12">
            <TaxAndFeeComponent onSubmit={handleOnSubmitOrder} />
          </div>
        </div>
      </div>
    </>
  );
};

export default C2cOrderPaymentContainer;
