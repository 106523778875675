import { UnitType } from 'Components/Enums';
import FieldUnit from 'Components/FieldUnit/fieldUnit';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { FieldUnitModel } from 'Components/Models';
import {
  C2C_MAX_SIZE,
  MESSAGE_ALERT_ERROR
} from 'Pages/C2cOrderPayment/constants';
import {
  C2cOnChangeTypeAction,
  C2cOverPaymentTextEnum
} from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  onChangeHeightAction,
  onChangeLongAction,
  onChangeWidthAction
} from 'Stores/c2c-order-payment';
import { toUpperLetter } from 'Utilities/util';
import './styles.scss';

interface SelectSizeProps {
	className?: string;
	onAfterChange?: () => void;
}

const SelectSize = (props: SelectSizeProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { className } = props;
	const { weightAndSize, c2cValidateFields, toggleOrder, checkDimWeight } =
		useSelector(c2cOrderPaymentSelectors.get);
	const fieldUnit: FieldUnitModel = {
		type: UnitType.Input,
		unit: 'cm',
	};

	const handleOnChange = (value: number, action: C2cOnChangeTypeAction) => {
		switch (action) {
			case C2cOnChangeTypeAction.Longs:
				dispatch(onChangeLongAction(value ? value : undefined));
				break;

			case C2cOnChangeTypeAction.Width:
				dispatch(onChangeWidthAction(value ? value : undefined));
				break;

			case C2cOnChangeTypeAction.Height:
				dispatch(onChangeHeightAction(value ? value : undefined));
				break;

			default:
				break;
		}
	};

	return (
		<div className={`${className} select-size`}>
      <div style={{visibility: 'visible'}}></div>
			<div className="select-size-wrap">
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Longs)}
					</div>
					<FieldUnit
						className="select-weight-unit"
						item={fieldUnit}
						value={weightAndSize?.longs}
						min={0}
						max={C2C_MAX_SIZE}
						onBlurChange={(value) =>
							handleOnChange(value, C2cOnChangeTypeAction.Longs)
						}
					></FieldUnit>
				</div>
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Width)}
					</div>
					<FieldUnit
						item={fieldUnit}
						className="select-weight-unit"
						value={weightAndSize?.width}
						min={0}
						max={C2C_MAX_SIZE}
						onBlurChange={(value) =>
							handleOnChange(
								Number(value),
								C2cOnChangeTypeAction.Width,
							)
						}
					></FieldUnit>
				</div>
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Height)}
					</div>
					<FieldUnit
						item={fieldUnit}
						className="select-weight-unit"
						value={weightAndSize?.height}
						min={0}
						max={C2C_MAX_SIZE}
						onBlurChange={(value) =>
							handleOnChange(
								Number(value),
								C2cOnChangeTypeAction.Height,
							)
						}
					></FieldUnit>
				</div>
			</div>
			{!!c2cValidateFields?.size?.hasError && (
				<MessageAlert
					{...c2cValidateFields?.size}
					toggle={toggleOrder}
					className="message-alert-size"
				/>
			)}

			{!!(!c2cValidateFields?.size?.hasError && checkDimWeight && checkDimWeight.isCheckDimWeight) && (
				<MessageAlert
					{...MESSAGE_ALERT_ERROR}
					message={toUpperLetter(checkDimWeight.errMsg, false)}
					toggle={toggleOrder}
          className="message-alert-size"
				/>
			)}
		</div>
	);
};

export default SelectSize;
