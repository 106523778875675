import { cookieStorage } from '@swift-247/s247.library.ui-core';
import { format as dateFormatFn, isValid, parse } from 'date-fns';
import i18next from 'i18next';
import { toLower } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DATE_FORMATS, TypeCurrency, TypePayment } from 'Utilities/constants';
import buildTimeConfig from '../../config/buildtime.config';
import { OrderBillPathEnum } from '../routes/enums';

export const rangeArray = (from: number, to: number, step = 1) => {
	const result = [];
	if(from <= to) {
		for(let i = from; i <= to; i+=step) {
			result.push(i);
		}

		return result;
	} else {
		for(let i = to; i <= from; i+=step) {
			result.unshift(i);
		}

		return result;
	}
}


export const removeVietnameseTones = (str: string = '') => {
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");
	str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i");
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");
	str = str.replace(/đ/g,"d");
	str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
	str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
	str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
	str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
	str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
	str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
	str = str.replace(/Đ/g, "D");
	// Some system encode vietnamese combining accent as individual utf-8 characters
	// Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
	str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
	// Remove extra spaces
	// Bỏ các khoảng trắng liền nhau
	str = str.trim();
	return str;
}

export const formatToCurrency = (amount?: number, convertToInt = false) => {
	if(amount === undefined || amount === null) return 0;
	let amountToFormat = amount;
	if(convertToInt) amountToFormat = parseInt(amountToFormat + '');

	return amountToFormat.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
};

export const copyToClipboard = (content?: string) => {
	if(!content) return;

	navigator.clipboard.writeText(content).then(() => {
		/* clipboard successfully set */
		toast.success((i18next.t('global_copy_status') as string), {
			position: toast.POSITION.TOP_RIGHT,
		});
	}, function() {
		/* clipboard write failed */
	});
}

export const isWhitelistedHost = (domains: string[], inspectedUrl: string):boolean => {
	try {
		const inspectedUrlInstance = new URL(inspectedUrl);
		return domains.includes(inspectedUrlInstance.host);
	} catch (err) {
		console.error(err);
		return false
	}
}

export const compareOnUISearchTexts = (searchValue: string, value: string) => {
	const lowerCasedSearchValue = toLower(searchValue);
	const unTonedOValue = removeVietnameseTones(value || '');
	return toLower(value).includes(lowerCasedSearchValue) || toLower(unTonedOValue).includes(lowerCasedSearchValue);
}

export const isLiveEnvironment = buildTimeConfig.env !== 'local';
export const envStorage = isLiveEnvironment ? localStorage : cookieStorage;

export const authUrl = '/auth';

export const unauthorizedKick = () => {
	if(isLiveEnvironment) {
		sessionStorage.removeItem('persist:login')
		envStorage.removeItem('authInfo');

		return window.location = authUrl as any;
	}
	return console.error('Unauthorized')
}

export const logUserOut = () => {
	envStorage.removeItem('authInfo');
	sessionStorage.removeItem('persist:login')
	unauthorizedKick()
}

// Use this method instead of new Date() to guarantee cross-browser compatible
export const newDateWithFormat = (value: string, format = DATE_FORMATS.ISO_8601): Date => {
	return parse(value, format, new Date());
}

export const formatTime = (date: string | Date , format = 'dd/MM/yyyy HH:mm', fallbackValue = '') => {
	let dateToFormat = date;
	if(typeof date === 'string') {
		dateToFormat = newDateWithFormat(date)
	}

	return isValid(dateToFormat) ? dateFormatFn(dateToFormat as Date, format) : fallbackValue
}

export const numberWithCommas = (x: string | number = '0') => {
	if (!x) return x;
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const displayCurrency =(price: any, currency: string | undefined = TypeCurrency.VND)=> {
	return currency === TypeCurrency.VND
		? `${numberWithCommas(price)} ${currency || ""}`
		: `${currency || ""} ${numberWithCommas(price)}`;
}

export const getTimeLocal = ({
	                             time = moment(),
	                             currentFormat = 'YYYY-MM-DD HH:mm:ss',
	                             format = 'DD/MM/YYYY HH:mm',
                             }: {
	time: string | moment.Moment;
	currentFormat?: string;
	format?: string;
}) => {
	// get device timezone eg. -> "Asia/Shanghai"
	const timeLocal = moment(moment.utc(time, currentFormat).toDate()).local().format(format);

	return timeLocal;
};


export const getConfirmationUrl = (paymentType: TypePayment, paymentSuccess: boolean) => {
	return `/${OrderBillPathEnum.OrderBillConfirmation}?paymentType=${paymentType}&paymentSuccess=${paymentSuccess}`;
}

export const randomBase64String = () => {
	return btoa(Math.random().toString());
}

export const capitalizeFirstLetter = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export const toUpperLetter = (text: string | undefined, isUpper = true) => {
	if (isUpper) {
    return ((' ' + text).slice(1) || '')?.toLocaleUpperCase();
	}
  return ((' ' + text).slice(1) || '')?.toLocaleLowerCase();
}

export const formatDateTimeParamUrl = (
	date: string,
	dateFormat = DATE_FORMATS.DDMMYYYYHHmm,
) => moment(date, dateFormat).utcOffset(0).format();

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const formatZeroTimezone = (date: string,) => moment(date).utcOffset(0).format(DATE_FORMATS.ISO_TIMEZONE);

export const formatDateYyyyMmDd = (date: string) =>  new Date(date).toISOString().slice(0, 10);

export const formatTimeHhMmUTC = (date: Date) => `${date?.getUTCHours()}:${date?.getUTCMinutes()}`;
