import iconTopUp from 'Assets/icon/bonus-point.svg';
import cancelledPackageIconUrl from 'Assets/icon/cancelled-package-icon.svg';
import icChecked from 'Assets/icon/checked-icon.svg';
import deliveredPackageIconUrl from 'Assets/icon/delivered-package-icon.svg';
import deliveringPackageIconUrl from 'Assets/icon/delivering-package-icon.svg';
import enFlagSquareUrl from 'Assets/icon/en.svg';
import errorMessageIconUrl from 'Assets/icon/error-icon.svg';
import fromToArrowIconUrl from 'Assets/icon/from-to-arrow-icon.svg';
import infoMessageIconUrl from 'Assets/icon/info-message-icon.svg';
import krFlagSquareUrl from 'Assets/icon/kr.svg';
import loadingPackageIconUrl from 'Assets/icon/loading-package-icon.svg';
import orderEmptyIconUrl from 'Assets/icon/order-empty-icon.svg';
import orderItemEmptyIconUrl from 'Assets/icon/order-item-empty-icon.svg';
import packageIconUrl from 'Assets/icon/package-icon.svg';
import profilerIconUrl from 'Assets/icon/profile-icon.svg';
import successMessageIconUrl from 'Assets/icon/success-message-icon.svg';
import viFlagSquareUrl from 'Assets/icon/vi.svg';
import hamburgerMenuIconUrl from './icon/hamburger-menu-icon.svg';
import QR from './icon/QR.svg';

import bankBNK from 'Assets/images/banks/bank_bnk.png';
import bankCitiBank from 'Assets/images/banks/bank_citi_bank.png';
import bankDaegu from 'Assets/images/banks/bank_daegu.png';
import bankHana from 'Assets/images/banks/bank_hana.png';
import bankIBK from 'Assets/images/banks/bank_ibk.png';
import bankJCB from 'Assets/images/banks/bank_jcb.png';
import bankKT from 'Assets/images/banks/bank_kt.png';
import bankMasterCard from 'Assets/images/banks/bank_mastercard.png';
import bankShinHan from 'Assets/images/banks/bank_shinhan.png';
import bankSKT from 'Assets/images/banks/bank_skt.png';
import bankSuHyUp from 'Assets/images/banks/bank_suhyup.png';
import bankTU from 'Assets/images/banks/bank_tu.png';
import bankVISA from 'Assets/images/banks/bank_visa.png';
import bgPayment from 'Assets/images/bg-payment.png';
import bgPremium from 'Assets/images/bg-premium.png';
import bgStandard from 'Assets/images/bg-standard.png';
import bgSuper from 'Assets/images/bg-super.png';
import cash from 'Assets/images/cash.png';
import D2D from 'Assets/images/D2D.png';
import D2P from 'Assets/images/D2P.png';
import icCopy from 'Assets/images/ic-copy.png';
import icLogoVbank from 'Assets/images/ic-logo-vbank.png';
import icPayco from 'Assets/images/ic-payco.png';
import icPremium from 'Assets/images/ic-premium.png';
import icRocket from 'Assets/images/ic-rocket.png';
import icSamSungPay from 'Assets/images/ic-samsung-pay.png';
import icStandard from 'Assets/images/ic-standard.png';
import icVBANK from 'Assets/images/ic-vbank.png';
import icVNPAY from 'Assets/images/ic-vnpay.png';
import P2D from 'Assets/images/P2D.png';
import P2P from 'Assets/images/P2P.png';

export default {
  cancelledPackageIconUrl,
  deliveredPackageIconUrl,
  deliveringPackageIconUrl,
  loadingPackageIconUrl,
  packageIconUrl,
  fromToArrowIconUrl,
  profilerIconUrl,
  viFlagSquareUrl,
  enFlagSquareUrl,
  krFlagSquareUrl,
  orderEmptyIconUrl,
  orderItemEmptyIconUrl,
  hamburgerMenuIconUrl,
  D2D,
  D2P,
  P2D,
  P2P,
  successMessageIconUrl,
  infoMessageIconUrl,
  errorMessageIconUrl,
  bgPayment,
  bankHana,
  bankDaegu,
  bankIBK,
  bankCitiBank,
  bankSuHyUp,
  bankShinHan,
  bankSKT,
  bankKT,
  bankTU,
  bankVISA,
  bankMasterCard,
  bankJCB,
  bankBNK,
  cash,
  bgStandard,
  bgPremium,
  bgSuper,
  icStandard,
  icPremium,
  icRocket,
  icVNPAY,
  icVBANK,
  icPayco,
  icSamSungPay,
  icLogoVbank,
  iconTopUp,
  icChecked,
  QR,
  icCopy
};
