import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import AddressServiceV1 from 'Services/v1/address-book';
import { DTOAddress, DTOAddressPayload } from 'Services/v1/address-book/dto';

import {
  createNewAddress,
  createNewAddressFailed,
  createNewAddressSuccess,
  deleteAddress,
  deleteAddressFailed,
  deleteAddressSuccess,
  getAccessibleLocation,
  getAccessibleLocationSuccess,
  getAddressBook,
  getAddressBookFailed,
  getAddressBookSuccess,
  updateAddress,
  updateAddressFailed,
  updateAddressSuccess,
} from './index';

export function* handleGetAddressBook(
  action: PayloadAction<DTOAddressPayload>,
): any {
  const { data, error, meta } = yield call(
    AddressServiceV1.listAddress.bind(AddressServiceV1),
    action.payload,
  );
  if (error) {
    yield put(
      getAddressBookFailed({
        errCode: error.code,
        errMsg: error.msg,
      }),
    );
    return;
  }
  yield put(getAddressBookSuccess({ data, meta }));
}

export function* handleCreateNewAddressBook(
  action: PayloadAction<DTOAddress>,
): any {
  const { data, error } = yield call(
    AddressServiceV1.addAddress.bind(AddressServiceV1),
    action.payload,
  );
  if (error) {
    yield put(
      createNewAddressFailed({
        errCode: error.code,
        errMsg: error.msg,
      }),
    );
    return;
  }

  yield put(createNewAddressSuccess(data));
}

export function* handleUpdateAddressBook(
  action: PayloadAction<DTOAddress>,
): any {
  const { data, error } = yield call(
    AddressServiceV1.updateAddress.bind(AddressServiceV1),
    action.payload.id || '',
    action.payload,
  );
  if (error) {
    yield put(
      updateAddressFailed({
        errCode: error.code,
        errMsg: error.msg,
      }),
    );
    return;
  }

  yield put(updateAddressSuccess());
}

export function* handleDeleteAddressBook(action: PayloadAction<string>): any {
  const { data, error } = yield call(
    AddressServiceV1.removeAddress.bind(AddressServiceV1),
    action.payload || '',
  );
  if (error) {
    yield put(
      deleteAddressFailed({
        errCode: error.code,
        errMsg: error.msg,
      }),
    );
    return;
  }

  yield put(deleteAddressSuccess());
}

export function* handleGetAccessibleLocation(): any {
  const { data, error } = yield call(
    AddressServiceV1.getAccessibleLocation.bind(AddressServiceV1),
	);
  if (error) {
    return;
  }

  yield put(getAccessibleLocationSuccess(data));
}

export default function* addressBookSaga() {
  yield takeLatest(getAddressBook.toString(), handleGetAddressBook);
  yield takeLatest(createNewAddress.toString(), handleCreateNewAddressBook);
  yield takeLatest(updateAddress.toString(), handleUpdateAddressBook);
  yield takeLatest(deleteAddress.toString(), handleDeleteAddressBook);
  yield takeLatest(getAccessibleLocation.toString(), handleGetAccessibleLocation);
}
