import {
  CaseReducerActions,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from '@reduxjs/toolkit';
import { FetchingStatusEnum } from 'Pages/common/Enums';

import {
  GeneralDataState,
  GetFailedActionPayload,
  GetMetaPayload,
  ListItemsState
} from 'Stores/models';

export const defaultListInitialState: ListItemsState<any> = {
  fetchingStatus: 'init',
  list: [],
  kind: 'list',
  meta: {}
};

export const defaultGeneralInitialState: GeneralDataState<any> = {
  fetchingStatus: 'init',
  data: {},
  kind: 'general'
};

export interface ListStateActions extends CaseReducerActions<any> {
  // TODO: It's hard to get redux-toolkit action type, back to this later
  getting: any;
  getSuccess: any;
  getFailed: any;
  getMetaSuccess: any;
  reset: any;
  resetFailed: any;
}

type ResourceType<R, T> = R extends ListItemsState<T>
  ? ListItemsState<T>
  : GeneralDataState<T>;

function createFetchedSlice<
  R,
  T,
  Reducer extends SliceCaseReducers<ResourceType<R, T>>
>(
  stateName: string,
  initialState: ResourceType<R, T>,
  extraReducers: ValidateSliceCaseReducers<
    ResourceType<R, T>,
    Reducer
  > = {} as any
) {
  return createSlice({
    name: stateName,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      getting: (state, action) => {
        state.fetchingStatus = FetchingStatusEnum.Pending;
      },
      getSuccess: (
        state: ResourceType<R, T>,
        action: PayloadAction<T | T[]>
      ) => {
        state.fetchingStatus = FetchingStatusEnum.Success;

        if (state.kind === 'general') {
          state.data = action.payload as T;
        } else {
          state.list = action.payload as T[];
        }
      },
      getMetaSuccess: (
        state: ResourceType<R, T>,
        action: PayloadAction<GetMetaPayload>
      ) => {
        state.fetchingStatus = FetchingStatusEnum.Success;
        if (state.kind === 'list') {
          const { meta } = action.payload;
          state.meta = meta;
        }
      },
      getFailed: (
        state: ResourceType<R, T>,
        action: PayloadAction<GetFailedActionPayload>
      ) => {
        const { errMsg, errCode } = action.payload;
        state.fetchingStatus = FetchingStatusEnum.Error;
        state.errMsg = errMsg;
        state.errCode = errCode;
        if (state.kind === 'general') {
          state.data = {} as T;
        } else if (state.kind === 'list') {
          state.list = [] as T[];
        }
      },
      reset: (state: ResourceType<R, T>) => {
        if (state.kind === 'general') {
          state.data = {} as T;
        } else if (state.kind === 'list') {
          state.list = [] as T[];
        }
      },
      resetFailed:(state: ResourceType<R, T>,) => {
        state.fetchingStatus = FetchingStatusEnum.Init;
        delete state.errMsg;
        delete state.errCode;
        if (state.kind === 'general') {
          state.data = {} as T;
        } else if (state.kind === 'list') {
          state.list = [] as T[];
        }
      },
      ...extraReducers
    }
  });
}

export default createFetchedSlice;
