import { Wrapper, Status } from '@googlemaps/react-wrapper';
import config from '../../../config/config';
import Map from './Map';
import Marker from './Marker';
import { useState } from 'react';

interface MapProps {
	center: google.maps.LatLngLiteral;
	zoom?: number;
	isShowMarker?: boolean;
	dragendChange: (lat: number, lng: number) => void;
}

const GMaps = (props: MapProps) => {
	const { center, zoom = 16, isShowMarker, dragendChange } = props;
	const [myImg, setMyImg] = useState(require('Assets/icon/marker.svg'));

	return (
		<div style={{ display: 'flex', height: '650px' }}>
			<Wrapper apiKey={config.googleKey}>
				<Map
					center={center}
					zoom={zoom}
					style={{ flexGrow: '1', height: '100%' }}
				>
					{isShowMarker && (
						<Marker
							position={center}
							draggable={true}
							dragendChange={dragendChange}
							icon={{
								url: myImg,
								scaledSize: new google.maps.Size(30, 30),
								origin: new google.maps.Point(0, 0),
								anchor: new google.maps.Point(0, 0),
							}}
						/>
					)}
				</Map>
			</Wrapper>
		</div>
	);
};

export default GMaps;
