import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DTOBasicPagination, DTOResponseMetaData } from 'Services/interfaces';
import {
	DTOAccessibleLocationPayload,
  DTOAddress,
  DTOAddressPayload,
  DTOAddressRaw,
} from 'Services/v1/address-book/dto';
import { RootState } from '..';
import { GetFailedActionPayload } from '../models';

export interface AddressState {
  fetching: {
    listingStatus: 'init' | 'pending' | 'error' | 'success';
    createStatus: 'init' | 'pending' | 'error' | 'success';
    updateStatus: 'init' | 'pending' | 'error' | 'success';
    deleteStatus: 'init' | 'pending' | 'error' | 'success';
    detailStatus: 'init' | 'pending' | 'error' | 'success';
  };
  list: DTOAddress[];
  meta: DTOResponseMetaData;
  selectedValue: DTOAddress;
  newAddress: DTOAddress;
  errMsg: string | undefined;
  errCode: string | number | undefined;
  urlNavigationReturn: string;
  accessibleLocation?: { [key:string]: string }[];
}

const initialState: AddressState = {
  fetching: {
    listingStatus: 'init',
    createStatus: 'init',
    updateStatus: 'init',
    deleteStatus: 'init',
    detailStatus: 'init',
  },
  list: [],
  meta: {
    include: [],
    custom: [],
    pagination: {},
  },
  selectedValue: {
    id: '',
    lat: '',
    long: '',
    province: '',
  },
  newAddress: {
    id: '',
    lat: '',
    long: '',
    province: '',
  },
  errMsg: '',
errCode: 0,
accessibleLocation: [],
urlNavigationReturn: '',
};

export const addressBookSlice = createSlice({
  name: 'addressBook',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getAddressBook: (state, action: PayloadAction<DTOAddressPayload>) => {
      state.fetching.listingStatus = 'pending';
      state.fetching.createStatus = 'init';
      state.fetching.updateStatus = 'init';
      state.fetching.deleteStatus = 'init';
    },
    getAddressBookSuccess: (
      state,
      action: PayloadAction<{ data: DTOAddress[]; meta: DTOResponseMetaData }>,
    ) => {
      state.fetching.listingStatus = 'success';
      state.list = action.payload.data;
      state.meta = action.payload.meta;
      state.selectedValue = {
        id: '',
        lat: '',
        long: '',
        province: '',
      };
    },
    getAddressBookFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>,
    ) => {
      state.fetching.listingStatus = 'error';
      state.errMsg = action.payload.errMsg;
      state.errCode = action.payload.errCode;
    },
    getAddressBookDetail: (state, action: PayloadAction<DTOAddress>) => {
      state.fetching.detailStatus = 'pending';
      state.selectedValue = action.payload;
    },
    createNewAddress: (state, action: PayloadAction<DTOAddressRaw>) => {
      state.fetching.createStatus = 'pending';
      state.newAddress = {
        id: '',
        lat: '',
        long: '',
        province: '',
      };
    },
    createNewAddressSuccess: (state, action: PayloadAction<DTOAddress>) => {
      state.fetching.createStatus = 'success';
      state.newAddress = action.payload;
    },
    createNewAddressFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>,
    ) => {
      state.fetching.createStatus = 'error';
      state.errMsg = action.payload.errMsg;
      state.errCode = action.payload.errCode;
    },
    updateAddress: (state, action: PayloadAction<DTOAddressRaw>) => {
      state.fetching.updateStatus = 'pending';
    },
    updateAddressSuccess: (state) => {
      state.fetching.updateStatus = 'success';
      state.selectedValue = {
        id: '',
        lat: '',
        long: '',
        province: '',
      };
    },
    updateAddressFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>,
    ) => {
      state.fetching.updateStatus = 'error';
      state.errMsg = action.payload.errMsg;
      state.errCode = action.payload.errCode;
    },
    deleteAddress: (state, action: PayloadAction<string>) => {
      state.fetching.deleteStatus = 'pending';
    },
    deleteAddressSuccess: (state) => {
      state.fetching.deleteStatus = 'success';
    },
    deleteAddressFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>,
    ) => {
      state.fetching.deleteStatus = 'error';
      state.errMsg = action.payload.errMsg;
      state.errCode = action.payload.errCode;
    },
    resetStatus: (state) => {
      state.fetching = initialState.fetching;
	},
	getAccessibleLocation: (state) => {
	},
	getAccessibleLocationSuccess: (state, action: PayloadAction<DTOAccessibleLocationPayload[]>) => {
		state.accessibleLocation = action.payload;
	},
  updateUrlNavigationReturn: (state, action: PayloadAction<string>) => {
    state.urlNavigationReturn = action.payload;
  },
  },
});

export const {
  getAddressBook,
  getAddressBookSuccess,
  getAddressBookFailed,
  getAddressBookDetail,
  createNewAddress,
  createNewAddressSuccess,
  createNewAddressFailed,
  updateAddress,
  updateAddressSuccess,
  updateAddressFailed,
  deleteAddress,
  deleteAddressSuccess,
  deleteAddressFailed,
	resetStatus,
	getAccessibleLocation,
	getAccessibleLocationSuccess,
  updateUrlNavigationReturn,
} = addressBookSlice.actions;

export const addressBookSelectors = {
  get: (state: RootState) => state.addressBook,
};

export default addressBookSlice.reducer;
