import { Col, Collapse, Form, Row, Select } from 'antd';
import markerBlue from 'Assets/icon/marker-blue.svg';
import marker from 'Assets/icon/marker.svg';
import ButtonClick from 'Components/ButtonClick';
import { CheckBoxItem } from 'Components/CheckBox';
import GMaps from 'Components/GMaps';
import InputText from 'Components/InputText';
import ModalDialog from 'Components/ModalDialog';
import RadioButton from 'Components/RadioButton';
import Section from 'Components/Section';
import SelectBox from 'Components/SelectBox';
import { replace, startsWith } from 'lodash';
import PageTitle from 'Pages/common/PageTitle';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressBookServiceV1 from 'Services/v1/address-book';
import { DTOAddress, DTOAddressRaw } from 'Services/v1/address-book/dto';
import LocationServiceV1 from 'Services/v1/locations';
import { DTOAddressKorea, DTOLocationOption } from 'Services/v1/locations/dto';
import {
  addressBookSelectors,
  createNewAddress,
  deleteAddress,
  resetStatus,
  updateAddress
} from 'Stores/address-book';
import {
  getLocations,
  getLocationsByAddress,
  getSelectedLocation,
  locationSelectors,
  resetLocation
} from 'Stores/locations';
import {
  PHONES_CODE,
  PHONE_CODE_NUMBER,
  REGREX_UTIL, VIETNAM_COUNTRY_CODE
} from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { AddressPathEnum, MainPageEnum } from '../../../routes/enums';

import './index.scss';

const initialData: DTOAddress = {
  id: '',
  personName: '',
  phone: '',
  address: '',
  province: '',
  district: '',
  village: '',
  isDefault: false,
  lat: 0,
  long: 0,
  type: 'NORMAL',
  country: '0',
	cardId: '',
	post_code: '',
  cardId: ''
};

const { Panel } = Collapse;
const provinceAccess: number[] = [22, 61, 62, 28, 2];

const initAddress: DTOAddress = {
  id: '',
  lat: '',
  long: '',
  province: ''
};

const AddressForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [fullAddress, setFullAddress] = useState('');

  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [showMarker, setShowMarker] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [originalAddress, setOriginalAddress] = useState('');

  const deleteStatus: string = useSelector(addressBookSelectors.get).fetching
    .deleteStatus;
  const updateStatus: string = useSelector(addressBookSelectors.get).fetching
    .updateStatus;
  const createStatus: string = useSelector(addressBookSelectors.get).fetching
    .createStatus;

  const countryData = useSelector(locationSelectors.get).country.data || [];
  const provinceData = useSelector(locationSelectors.get).city.data || [];
  const districtData: DTOLocationOption[] =
    useSelector(locationSelectors.get).district.data || [];
  const villageData: DTOLocationOption[] =
    useSelector(locationSelectors.get).ward.data || [];
  const pickupLocation = useSelector(locationSelectors.get).pickup;
  const allPickupLocation = useSelector(locationSelectors.get);
  const wardList = useSelector(locationSelectors.get).ward.list;

  const [searchParams] = useSearchParams();
  const addressType = searchParams.get('addressType') || undefined;
  const orderType = searchParams.get('orderType') || undefined;
  const page = searchParams.get('page') || '1';

	const isGlobalCountry = () => form.getFieldValue('country') !== VIETNAM_COUNTRY_CODE;

  const pageIndex = useRef(1);
  const totalPage = useRef(0);
  const isLoading = useRef(false);
  const txtSearch = useRef('');
	const [addressBuildingNumberList, setAddressBuildingNumberList] = useState<DTOAddressKorea[]>([]);
  const [addressDetail, setAddressDetail] = useState(initAddress);

  useEffect(() => {
    if (id) {
      AddressBookServiceV1.getAddress(id).then(data => {
        if (data?.data) {
          setAddressDetail(data?.data);
        }
      });
    }
  }, [id]);

  const backToPreviousList = () => {
    if (addressType) {
      const previousAddressListPath = generatePath(
        AddressPathEnum.AddressListingSelect,
        {
          addressType,
          orderType
        }
      );
      navigate(`${previousAddressListPath}?page=${page}`);
    } else {
      navigate('/' + MainPageEnum.Address + `?page=${page}`);
    }
  };

  const checkBoxOptions: CheckBoxItem[] = [
    { label: t('checkbox_normal_address'), value: 'NORMAL' },
    { label: t('checkbox_default_receiver_address'), value: 'RECEIVER' },
    { label: t('checkbox_default_sender_address'), value: 'SENDER' }
  ];

  const compClass = `${classPrefix}-address-detail`;
	const handleGetAddressBuildingNumber = (listData: DTOAddressKorea[] = []) => {
    const { village } = form.getFieldsValue();
    LocationServiceV1.getAddressKorea(
      village,
      pageIndex.current,
      txtSearch.current
    ).then(res => {
      totalPage.current = Number(res.meta?.totalPages || 0);
			const newData = [...listData, ...(res.data! || [])];
			setAddressBuildingNumberList(newData);
      isLoading.current = false;
    });
  };

  const onSearch = (text: string) => {
    txtSearch.current = text;
    pageIndex.current = 1;
		handleGetAddressBuildingNumber();
  };

  const onScrollBottom = (e: any) => {
    if (!isLoading.current && totalPage.current > pageIndex.current) {
      isLoading.current = true;
      pageIndex.current += 1;
			handleGetAddressBuildingNumber(addressBuildingNumberList);
    }
  };

  const onSelectChange = (value: string, options: any, field: string) => {
    let fieldReset: string[] = [];
    let filedResetState: any[] = [];
    if (field !== 'address') {
      pageIndex.current = 1;
      txtSearch.current = '';
			setAddressBuildingNumberList([]);
    }
    switch (field) {
      case 'country':
        fieldReset = [
          'province',
          'district',
          'village',
          'address',
          'addressDetail'
        ];
        filedResetState = ['city', 'district', 'ward', 'address'];
        dispatch(getLocations({ level: 'city', id: value }));
        dispatch(getSelectedLocation({ level: 'country', id: value }));
        break;
      case 'province':
        fieldReset = ['district', 'village', 'address'];
        filedResetState = ['district', 'ward'];
        dispatch(getLocations({ level: 'district', id: value }));
        setShowMarker(provinceAccess.includes(Number(value)));
        dispatch(getSelectedLocation({ level: 'city', id: value }));
        break;
      case 'district':
        fieldReset = ['village', 'address'];
        filedResetState = ['ward'];
        dispatch(getLocations({ level: 'ward', id: value }));
        dispatch(
          getSelectedLocation({
            level: 'district',
            id: value
          })
        );
        break;
      case 'village':
        fieldReset = ['address'];
				if (isGlobalCountry()) handleGetAddressBuildingNumber();
        dispatch(getSelectedLocation({ level: 'ward', id: value }));
        const { address } = form.getFieldsValue();
        if (!address && Object.values(wardList).length) {
          // get location of ward
          let wardLatLng = { lat: 0, lon: 0 };
          for (const k in wardList) {
            if (Object.prototype.hasOwnProperty.call(wardList, k)) {
              const e = wardList[k];
              if (e.id === value) {
                wardLatLng = {
                  lat: Number(e.geo.lat),
                  lon: Number(e.geo.lon)
                };
              }
            }
          }
          if (wardLatLng.lat && wardLatLng.lon) {
            setCenter({
              lat: wardLatLng.lat,
              lng: wardLatLng.lon
            });
          }
        }
        break;
      default:
        break;
    }
    form.resetFields(fieldReset);
    filedResetState.forEach(field => {
      dispatch(resetLocation({ level: field }));
    });
  };

  const onChangeAddress = () => {
    const { address } = form.getFieldsValue();
    if (id && address === addressDetail.address) {
      const lat = form.getFieldValue('lat');
      const lng = form.getFieldValue('long');
      setCenter({
        lat: Number(lat),
        lng: Number(lng)
      });
    } else {
      setFullAddress(getFullAddress(address));
    }
  };

  useEffect(() => {
    dispatch(getLocations({ level: 'country', id: '' }));
    dispatch(getLocations({ level: 'city', id: '0' }));
    if (id) {
      // get location
      if (addressDetail.country) {
        dispatch(
          getLocations({
            level: 'city',
            id: addressDetail.country
          })
        );
      }

      let prefixPhone = PHONE_CODE_NUMBER.VIETNAM;
      if (addressDetail.phone) {
        if (startsWith(addressDetail.phone, PHONE_CODE_NUMBER.KOREA)) {
          prefixPhone = PHONE_CODE_NUMBER.KOREA;
        }
        addressDetail.phone = replace(addressDetail.phone, prefixPhone, '0');
      }

      if (addressDetail.province) {
        dispatch(
          getLocations({
            level: 'district',
            id: addressDetail.province
          })
        );
        setShowMarker(provinceAccess.includes(Number(addressDetail.province)));
      }

      if (addressDetail.district) {
        dispatch(getLocations({ level: 'ward', id: addressDetail.district }));
      }

			if(addressDetail?.address){
				const addressToList = addressDetail.address.split(',');
				if(addressToList.length > 4) {
					for (let index = 0; index < 4 ; index++) {
						addressToList.pop();
					}
				}

				addressDetail.address = addressToList.toString();
			}

      addressDetail?.address && setOriginalAddress(addressDetail.address);
      form && form.setFieldsValue({ ...addressDetail, prefixPhone });

			if (addressDetail.address) {
				handleGetAddressBuildingNumber();
      }

			addressDetail?.address && setOriginalAddress(addressDetail.address);
	    form && form.setFieldsValue({ ...addressDetail, prefixPhone });

	    if (addressDetail.address) {
		    handleGetAddressBuildingNumber();
	    }


      form.setFieldsValue({
        type: addressDetail.isDefault ? addressDetail.type : 'NORMAL'
      });
    } else {
      form.setFieldsValue({ country: '0', type: 'NORMAL', prefixPhone: PHONE_CODE_NUMBER.VIETNAM });
    }
  }, [addressDetail]);

  useEffect(() => {
    const { province, district, village } = form.getFieldsValue();
    province &&
      provinceData.length &&
      dispatch(
        getSelectedLocation({
          level: 'city',
          id: province
        })
      );
    district &&
      districtData.length &&
      dispatch(
        getSelectedLocation({
          level: 'district',
          id: district
        })
      );
    village &&
      villageData.length &&
      dispatch(
        getSelectedLocation({
          level: 'ward',
          id: village
        })
      );

    const formAddress = form.getFieldValue('address');
    if (id && formAddress === originalAddress) {
      const lat = form.getFieldValue('lat');
      const lng = form.getFieldValue('long');
      setCenter({
        lat: Number(lat),
        lng: Number(lng)
      });
    } else if (formAddress) {
      setFullAddress(getFullAddress(formAddress));
    }
  }, [provinceData, districtData, villageData]);

  useEffect(() => {
    if (fullAddress.length) {
      dispatch(getLocationsByAddress(fullAddress));
    }
  }, [fullAddress]);

  useEffect(() => {
    if (pickupLocation) {
      setCenter({
        lat: pickupLocation.lat,
        lng: pickupLocation.lng
      });
    }
  }, [pickupLocation]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      toast.success(t('message_delete_success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      setTimeout(() => {
        backToPreviousList();
      }, 500);
    }
    if (deleteStatus === 'error') {
      toast.error(t('message_update_error'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    if (createStatus === 'success') {
      toast.success(t('message_create_success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      setTimeout(() => {
        backToPreviousList();
      }, 500);
    }
    if (createStatus === 'error') {
      toast.error(t('message_create_error'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    if (updateStatus === 'success') {
      toast.success(t('message_update_success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      setTimeout(() => {
        backToPreviousList();
      }, 500);
    }
    if (updateStatus === 'error') {
      toast.error(t('message_update_error'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    dispatch(resetStatus());
  }, [deleteStatus, createStatus, updateStatus]);

  const onSubmit = (data: DTOAddressRaw & { prefixPhone: string }) => {
    setLoading(true);
    const formData: DTOAddressRaw & { prefixPhone: string } = {
      ...initialData,
      ...data,
      address: getFullAddress(String(data?.address))
    };
    if (formData.type === 'SENDER' || formData.type === 'RECEIVER') {
      formData.is_default = true;
    } else {
      // set default value as sender
      formData.type = 'SENDER';
    }
    formData.lat = center?.lat;
    formData.long = center?.lng;
    if (isGlobalCountry()) {
      formData.address = data.address
    }
	  if (formData.phone) {
		  let phone = formData.phone;
		  if (startsWith(formData.phone, '0')) {
			  phone = replace(formData.phone, '0', '');
		  }
		  formData.phone = formData.prefixPhone + phone;
	  }

		formData.post_code = data.postCode;

    if (id) {
      formData.id = id;
      dispatch(updateAddress(formData));
    } else {
      delete formData.id;
      dispatch(createNewAddress(formData));
    }
  };

  const onDelete = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    backToPreviousList();
  };

  const onHandleCancelModal = () => {
    setVisibleModal(false);
  };

  const onHandleOkModal = () => {
    if (id) {
      dispatch(deleteAddress(id));
    }
    setVisibleModal(false);
  };

  const dragendChange = (lat: number, lng: number) => {
    if (lat && lng) {
      setCenter({
        lat: lat,
        lng: lng
      });
    }
  };

  const getFullAddress = (address: string) => {
    return `${address}, ${allPickupLocation.ward.selectedValue}, ${
      allPickupLocation.district.selectedValue
    }, ${allPickupLocation.city.selectedValue}, ${
      allPickupLocation.country.selectedValue || 'Việt Nam'
    }.`;
  };

  const validateMessages = {
    required: t('validation_required', { fieldName: '${label}' })
  };

  const prefixPhone = (
    <Form.Item name="prefixPhone" noStyle>
      <Select
        style={{
          width: 90
        }}
      >
        {PHONES_CODE.map((phoneCode, index) => (
          <Option key={index} value={phoneCode.value}>
            <div className="d-flex align-items-center">
            <img className="me-1" width={30} height={15} src={phoneCode.icon} alt={phoneCode.label} />
            {phoneCode.label}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <PageTitle title={t('title_page_address')} />
      <Section
        className={`${compClass}-container pb-4`}
        title={t('title_update_address')}
        icon={<img src={marker} width="14" alt={''} />}
        actionIcon={
          id ? (
            <a className="delete-btn" onClick={onDelete}>
              {t('buttons_delete')}
            </a>
          ) : undefined
        }
      >
        <ModalDialog
          visible={visibleModal}
          title={t('modal_title_confirm')}
          children={t('modal_content_delete_confirm')}
          cancelText={t('buttons_cancel')}
          okText={t('buttons_delete')}
          onHandleCancel={onHandleCancelModal}
          onHandleOk={onHandleOkModal}
        />
        <div className={`${compClass}-form mt-6`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{ country: '0' }}
            validateMessages={validateMessages}
          >
            <Row gutter={24}>
              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="personName"
                  rules={[
                    { required: true },
                    {
                      pattern: REGREX_UTIL.spacesStartOrEnd,
                      message: t('validation_spaces_full_name')
                    }
                  ]}
                  label={t('label_full_name')}
                >
                  <InputText
                    autoFocus
                    maxLength={45}
                    placeholder={t('label_full_name')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  className="phone-form"
                  name="phone"
                  rules={[
                    {
                      required: true
                    },
                    {
                      pattern: /^[0-9]{9,11}$/,
                      message: t('validation_range_length_phone', {
                        min: 0,
                        max: 9
                      })
                    }
                  ]}
                  label={t('label_phone')}
                >
                  <InputText
                    addonBefore={prefixPhone}
                    type="number"
                    className="hide-arrow"
                    placeholder={t('label_phone')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="cardId"
                  rules={[
                    {
                      pattern: /[0-9]{9,}/,
                      message: t('invalid_CMND_CCCD')
                    }
                  ]}
                  label={t('label_identity')}
                >
                  <InputText
                    maxLength={12}
                    minLength={9}
                    placeholder={t('label_identity')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="country"
                  rules={[{ required: true }]}
                  label={t('label_country')}
                >
                  <SelectBox
                    selectedValue={form.getFieldValue('country')}
                    onChange={(value: string, options: any) =>
                      onSelectChange(value, options, 'country')
                    }
                    options={countryData}
                    placeholder={t('label_country')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="province"
                  rules={[{ required: true }]}
                  label={t('label_province')}
                >
                  <SelectBox
                    selectedValue={form.getFieldValue('province')}
                    onChange={(value: string, options: any) =>
                      onSelectChange(value, options, 'province')
                    }
                    options={provinceData}
                    placeholder={t('label_province')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="district"
                  rules={[{ required: true }]}
                  label={t('label_district')}
                >
                  <SelectBox
                    selectedValue={form.getFieldValue('district')}
                    onChange={(value: string, options: any) =>
                      onSelectChange(value, options, 'district')
                    }
                    options={districtData}
                    placeholder={t('label_district')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="village"
                  rules={[{ required: true }]}
									label={t(isGlobalCountry() ? 'label_ward_korean' : 'label_ward')}
                >
                  <SelectBox
                    selectedValue={form.getFieldValue('village')}
                    onChange={(value: string, options: any) =>
                      onSelectChange(value, options, 'village')
                    }
                    options={villageData}
										placeholder={t(isGlobalCountry() ? 'label_ward_korean' : 'label_ward')}
                  />
                </Form.Item>
              </Col>
							{isGlobalCountry() ? (
								<Col span={12}>
                  <Form.Item
                    name="address"
                    rules={[{ required: true }]}
                    label={t('building_number')}
                  >
                    <SelectBox
                      selectedValue={form.getFieldValue('address')}
											onChange={(_, item) => {
												form.setFieldValue('postCode', item.data.postcode)
											}}
                      onScrollBottom={onScrollBottom}
                      isSearchServer
                      onSearch={onSearch}
                      valueField={'address'}
                      labelField={'address'}
											options={addressBuildingNumberList}
                      isShowCheck={false}
                      placeholder={t('building_number')}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="address"
                    rules={[{ required: true }]}
                    label={t('label_house_street_number')}
                  >
                    <InputText
                      placeholder={t('label_house_street_number')}
                      onChange={onChangeAddress}
                      maxLength={250}
                    />
                  </Form.Item>
                </Col>
              )}
							{isGlobalCountry() && (
                <Col span={24}>
                  <Form.Item name="addressDetail" label={t('detail_address')}>
                    <InputText
                      placeholder={t('detail_address')}
                      maxLength={250}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item
									name="postCode"
									rules={[
										{
											pattern: REGREX_UTIL.onlyCharsAndNums,
											message: t('validation_special_characters')
										}
									]}
									label={t('label_postcode')}
								>
									<InputText
										disabled={isGlobalCountry()}
										autoFocus
										maxLength={45}
										placeholder={t('label_postcode')}
									/>
								</Form.Item>
							</Col>

							{isGlobalCountry() && <Col span={12}>
								<Form.Item
									name='pccc'
									rules={[
										{
											pattern: REGREX_UTIL.onlyCharsAndNums,
											message: t('validation_special_characters')
										}
									]}
									label={t('label_pccc')}
								>
									<InputText
										autoFocus
										maxLength={45}
										placeholder={t('label_pccc')}
									/>
								</Form.Item>
							</Col>}
						</Row>


            <Row gutter={24}>
              <Col span={24}>
                <div className={`${classPrefix}-collapse-container mb-4`}>
                  <Collapse ghost bordered={false} expandIcon={() => <></>}>
                    <Panel
                      header={
                        <span className="pickup-location">
                          <img className="mr-2" src={markerBlue} width="14px" />{' '}
                          {t('title_pick_up_location')}
                        </span>
                      }
                      key="1"
                    >
                      {!!center.lat && !!center.lng && (
                        <GMaps
                          center={center}
                          isShowMarker={showMarker}
                          dragendChange={dragendChange}
                        />
                      )}
                      <span className="p-4 d-block">
                        {t('message_please_select_location_on_map')}
                      </span>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="type" label={t('label_type_address')}>
                  <RadioButton
                    options={checkBoxOptions}
                    radioGroupClassName="w-100 d-flex justify-content-start"
                  />
                </Form.Item>
              </Col>
            </Row>


						<Row >
              <Col xs={12} md={16} lg={16} xl={16}></Col>
              <Col xs={12} md={8} lg={8} xl={8}>
                <Form.Item className="btn-action">
                  <ButtonClick
                    containerClassName="mr-2"
                    className="w-100"
                    variant="ghost"
                    onClick={onCancel}
                  >
                    {t('buttons_cancel')}
                  </ButtonClick>
                  <ButtonClick
                    className="w-100"
                    variant="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t('buttons_complete')}
                  </ButtonClick>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default AddressForm;
