import { Tooltip } from 'antd';
import warningIcon from 'Assets/icon/warning-icon.svg';
import { ItemBaseModel } from 'Components/Models';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface ItemBaseProps {
  item: ItemBaseModel;
  className?: string;
  active?: boolean;
  disable?: boolean;
  messageTooltip?: string;
  onClick?: () => void;
  style?: {};
}

const ItemBase = (props: ItemBaseProps) => {
  const { t } = useTranslation();
  const {
    item,
    className = '',
    onClick = () => {},
    active,
    disable = false,
    messageTooltip,
    style = {}
  } = props;

  return (
    <div
      className={`${className} item-base ${active ? 'item-base--active' : ''} ${
        disable ? 'item-base-disable' : ''
      }`}
      style={style}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
    >
      {!!item && (
        <>
          <div className="item-base-wrap">
            <div className="item-base-container">
              <img src={item?.img} alt={''}></img>
            </div>
            {disable && (
              <Tooltip title={t(messageTooltip || '')}>
                <img
                  className="item-base-tooltip-icon"
                  src={warningIcon}
                  alt={''}
                />
              </Tooltip>
            )}
          </div>
          <span className="item-base-name">{item?.name}</span>
        </>
      )}
    </div>
  );
};

export default ItemBase;
