import images from 'Assets/images';
import { FetchingStatusEnum } from 'Pages/common/Enums';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { myOrdersSelectors, myOrdersSliceActions } from 'Stores/my-orders';
import AddressInfo from '../AddressInfo';
import EstimatedDelivery from '../EstimatedDelivery';
import { OrderEmptyItemDto } from '../Models';
import { OrderEmptyItem } from '../OrderEmptyItem';
import OrderTimeline from '../OrderTimeline';
import PaymentOrder from '../PaymentOrder';
import ProductOrder from '../ProductOrder';
const OrderDetail = () => {
  const dispatch = useDispatch();
  const orderDetailState = useSelector(
    myOrdersSelectors.get,
  ).orderDetailReducer;
  const orderDetail = orderDetailState.data;
  const orderItemEmpty: OrderEmptyItemDto = {
    iconUrl: images.orderItemEmptyIconUrl,
    message: 'Xin lỗi, đơn hàng này không tồn tại',
    callCenter: true,
  };
  const fetchingStatus = orderDetailState.fetchingStatus;

  const { id } = useParams();

  const renderOrderDetail = () => {
    return (
      <>
        {orderDetail.statusName === 'SENDING' &&
          orderDetail?.bookingShipperTime && (
            <EstimatedDelivery
              datetime={orderDetail?.bookingShipperTime}
            ></EstimatedDelivery>
          )}
        <AddressInfo {...orderDetail}></AddressInfo>
        <ProductOrder {...orderDetail}></ProductOrder>
          <OrderTimeline {...orderDetail}></OrderTimeline>
      <PaymentOrder data={orderDetail} fetchingStatus={fetchingStatus}></PaymentOrder>
      </>
    );
  };

  useEffect(() => {
    dispatch(myOrdersSliceActions.orderDetailAction.getting({ id }));
  }, []);

  return (
    <>
      {fetchingStatus === FetchingStatusEnum.Error && (
        <OrderEmptyItem data={orderItemEmpty}></OrderEmptyItem>
      )}
      {orderDetailState.fetchingStatus === FetchingStatusEnum.Success && renderOrderDetail()}
    </>
  );
};

export default OrderDetail;
