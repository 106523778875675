import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { convertLocationDataToSelectOption } from 'Services/data-mappers';
import { RootState } from '..';
import {
  GetLocationActionPayload,
  GetLocationByAddressSuccessActionPayload,
  GetLocationFailedActionPayload,
  GetLocationSuccessActionPayload,
  LocationState,
  ResetLocationActionPayload
} from './models';

const initialState: LocationState = {
	ward: {
		fetchingStatus: 'init',
		list: [],
		kind: 'list',
		data: [],
		selectedValue: '',
		meta: {}
	},
	district: {
		fetchingStatus: 'init',
		list: [],
		kind: 'list',
		data: [],
		selectedValue: '',
		meta: {}
	},
	city: {
		fetchingStatus: 'init',
		list: [],
		kind: 'list',
		data: [],
		selectedValue: '',
		meta: {}
	},
	country: {
		fetchingStatus: 'init',
		list: [],
		kind: 'list',
		data: [],
		selectedValue: '',
		meta: {}
	},
	pickup: {
		fetchingStatus: 'init',
		lat: 0,
		lng: 0,
		formattedAddress: '',
	},
};

export const locationsSlice = createSlice({
	name: 'locations',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		getLocations: (state, action: PayloadAction<GetLocationActionPayload>) => {
			const level = action.payload.level;
			state[level].fetchingStatus = 'pending';
		},
		getLocationsSuccess: (
			state,
			action: PayloadAction<GetLocationSuccessActionPayload>,
		) => {
			const { level, data } = action.payload;
			state[level].fetchingStatus = 'success';
			state[level].list = data;
			state[level].data = convertLocationDataToSelectOption(data);
		},
		getLocationsFailed: (
			state,
			action: PayloadAction<GetLocationFailedActionPayload>,
		) => {
			const {
				level,
				data: { errCode, errMsg },
			} = action.payload;
			state[level].fetchingStatus = 'error';
			state[level].errMsg = errMsg;
			state[level].errCode = errCode;
		},
		getLocationsByAddress: (
			state,
			action: PayloadAction<string>,
		) => {
			state.pickup.fetchingStatus = 'pending';
		},
		getLocationsByAddressSuccess: (
			state,
			action: PayloadAction<GetLocationByAddressSuccessActionPayload>,
		) => {
			state.pickup = {
				fetchingStatus: 'success',
				lat: Number(action.payload.lat),
				lng: Number(action.payload.lng),
				formattedAddress: action.payload.formattedAddress,
			};
		},
		getLocationsByAddressFailed: (
			state,
			action: PayloadAction<GetLocationFailedActionPayload>,
		) => {
			const {
				level,
				data: { errCode, errMsg },
			} = action.payload;
			state.pickup.fetchingStatus = 'error';
		},
		getSelectedLocation: (
			state,
			action: PayloadAction<GetLocationActionPayload>
		) => {
			const { level, id } = action.payload;
			const selected = state[level].data?.filter(item => item.value === id);
			state[level].selectedValue = selected && selected.length ? selected[0].label : '';
		},
		getResetLocation: (state) => {
			state['country'] = {
				fetchingStatus: 'init',
				list: [],
				kind: 'list',
				data: [],
				selectedValue: '',
				meta: {}
			}
			state['city'] = {
				fetchingStatus: 'init',
				list: [],
				kind: 'list',
				data: [],
				selectedValue: '',
				meta: {}
			}
			state['district'] = {
				fetchingStatus: 'init',
				list: [],
				kind: 'list',
				data: [],
				selectedValue: '',
				meta: {}
			}
			state['ward'] = {
				fetchingStatus: 'init',
				list: [],
				kind: 'list',
				data: [],
				selectedValue: '',
				meta: {}
			}
		},
    resetLocation: (state,
			action: PayloadAction<ResetLocationActionPayload>) => {
        if (state[action.payload.level]?.data) {
          state[action.payload.level].data = [];
        }
    }
	},
});

export const {
	getLocations,
	getLocationsSuccess,
	getLocationsFailed,
	getLocationsByAddress,
	getLocationsByAddressSuccess,
	getLocationsByAddressFailed,
	getSelectedLocation,
	getResetLocation,
  resetLocation
} = locationsSlice.actions;

export const locationSelectors = {
	get: (state: RootState) => state.locations,
};

export default locationsSlice.reducer;
