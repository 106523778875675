import AddressForm from "Pages/AddressBook/AddressDetail/AddressForm";
import AddressListing from "Pages/AddressBook/AddressListing";
import { RouteModel } from "..";
import { AddressPathEnum } from "../enums";

export const AddressRoutes:Array<RouteModel>  = [
  {
		name: 'Address',
		path: AddressPathEnum.Address,
		exact: true,
		sideBar: false,
		component: <AddressForm />,
	},
  {
		name: 'Address Form Create',
		path: AddressPathEnum.AddressFormCreate,
		exact: true,
		sideBar: false,
		component: <AddressForm />,
	},
	{
		name: 'Address Form Update',
		path: AddressPathEnum.AddressFormUpdate,
		exact: true,
		sideBar: false,
		component: <AddressForm />,
	},
	{
		name: 'Address Listing Select',
		path: AddressPathEnum.AddressListingSelect,
		exact: true,
		sideBar: true,
		component: <AddressListing isSelectable={true}/>,
	},
]

