import { Button } from '@swift-247/s247.library.ui-core';
import { ConfigProvider, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import iconNotFoundFlightUrl from 'Assets/icon/not-found-flight-icon.svg';
import images from 'Assets/images';
import { FlightTimeFormat } from 'Components/FlightListDisplayOnMobile';
import Spinner from 'Components/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DTOAvailableFlight } from 'Services/v1/flights/dto';
import { clearFeeAndTax, orderBillSelectors, updateFlightInfo } from 'Stores/order-bill';
import classnames from 'Utilities/classnames';
import { FLIGHT_LOAD_STATUS } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { formatToCurrency } from 'Utilities/util';
import { FlightListDisplayOnMobile } from '..';
import './index.scss';

interface FlightNumberTagProps {
  isActive?: boolean;
  value?: string | number;
}

const FlightNumberTag = (props: FlightNumberTagProps) => {
  const { isActive, value } = props;
  if (!value) return <></>;
  const containerClasses = classnames({
    [`${classPrefix}-flight-number-tag`]: true,
    [`${classPrefix}-flight-number-tag--active`]: isActive,
  });
  return (
    <div className={containerClasses}>
      <span>{value}</span>
    </div>
  );
};

interface SelectFlightTableProps {
  data: DTOAvailableFlight[];
  isLoading?: boolean;
}

const calculateLoadStatus = (remain: number, capacity: number | string) => {
  if (remain > 100) return FLIGHT_LOAD_STATUS.LOAD_ENOUGH;
  if (remain >= 0 && remain < 100) {
    if (parseInt(String(capacity)) !== -1) {
      return FLIGHT_LOAD_STATUS.LOAD_LOW;
    }
    return FLIGHT_LOAD_STATUS.LOAD_FULL;
  }
  if (remain < 0) return FLIGHT_LOAD_STATUS.LOAD_EXCEEDED;
};

const SelectFlightTable = (props: SelectFlightTableProps) => {
  const { data, isLoading } = props;
  const { t } = useTranslation();
  const selectedFlight = useSelector(orderBillSelectors.get).flightInfo;

  const dispatch = useDispatch();
  const columns: ColumnsType<DTOAvailableFlight> = [
    {
      title: t('select_flight_table_column_flight_number'),
      key: 'flightNumber',
      dataIndex: 'flightNumber',
      render: (_, { flightNumber, flightId }) => (
        <FlightNumberTag
          isActive={selectedFlight?.flightId === flightId}
          value={flightNumber}
        />
      ),
    },
    {
      title: t('select_flight_table_column_departed_time'),
      dataIndex: 'startTime',
      key: 'startTime',
      render: (_, { startTime }) => <FlightTimeFormat value={startTime} />,
    },
    {
			title: '',
      key: 'custom',
      className: `${classPrefix}-select-flight-table-column-custom-arrow`,
      render: () => <img src={images.fromToArrowIconUrl} alt={''} />,
    },
    {
      title: t('select_flight_table_column_arrived_time'),
      dataIndex: 'minEndTime',
      key: 'minEndTime',
      render: (_, { minEndTime }) => <FlightTimeFormat value={minEndTime} />,
    },
    {
      title: t('select_flight_table_column_current_load'),
      dataIndex: 'remainwt',
      key: 'remainwt',
      render: (value, item) => {
				return t(calculateLoadStatus(value, item.capacity) || '')
			}
    },
    {
      title: t('select_flight_table_column_delivery_fee'),
      key: 'price',
      dataIndex: 'price',
      render: (value) => {
        return `${formatToCurrency(value, true)}đ`;
      },
    },
    {
      title: t('select_flight_table_column_aircraft_type'),
      key: 'aircraftType',
      dataIndex: 'aircraft',
    },
    {
      title: '',
      key: 'action',
      render: (_, data) => {
        const isRowSelected = selectedFlight?.flightId === data.flightId;
        const flightLoadStatus = calculateLoadStatus(
          data.remainwt,
          data.capacity,
        );
        const isFlightSelectable =
          flightLoadStatus === FLIGHT_LOAD_STATUS.LOAD_ENOUGH ||
          flightLoadStatus === FLIGHT_LOAD_STATUS.LOAD_LOW;
        const renderedComp = isRowSelected ? (
          <Button
            onClick={() => {
              dispatch(updateFlightInfo(undefined));
              dispatch(clearFeeAndTax());
            }}
            size={'sm'}
            variant={'secondary'}
          >
	          {t('global_un_select')}
          </Button>
        ) : (
          <Button
            disabled={!isFlightSelectable}
            onClick={() => {
              dispatch(updateFlightInfo(data));
				}} size={'sm'}>{t('global_select')}</Button>)
				return renderedComp;
			}
		}
  ];

  return (
    <div className={`${classPrefix}-select-flight-table`}>
      <ConfigProvider
        renderEmpty={() => (
          <div className={`${classPrefix}-select-flight-table-empty`}>
            <img
              className={'mb-10'}
              width={218}
              height={128}
              src={iconNotFoundFlightUrl}
              alt={''}
            />
            <div
              className={`mb-2 ${classPrefix}-select-flight-table-empty-title text-secondary-900`}
            >
	            {t('select_flight_filter_flights_not_found')}
            </div>
            <div className={'fs-p-md fs-p-md text-secondary-700'}>
	            {t('select_flight_filter_flights_not_found_sub_text')}
            </div>
          </div>
        )}
      >
        <Table
          key={`${selectedFlight?.flightId}_${Math.random()?.toString()}`}
          rowSelection={{
            selectedRowKeys: selectedFlight?.flightId
              ? [selectedFlight?.flightId]
              : [],
            onSelect: (data) => {},
            type: 'radio',
          }}
          pagination={false}
          columns={columns}
          dataSource={data}
				rowKey={(record) => `${record.flightId}`} // TODO: Workaround for duplicated-key (causes incorrect rendering) -> need to be removed when backend resolved its issue
          loading={{
            spinning: isLoading,
            indicator: <Spinner />,
          }}
        />
        <div className="flight-list-display-on-mobile">
          <FlightListDisplayOnMobile data={data}></FlightListDisplayOnMobile>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default SelectFlightTable;
