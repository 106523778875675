import higherOrderSaga from 'Stores/hos';
import flightsSlice from './index';
import FlightServiceV1 from 'Services/v1/flights';
import { call, takeLatest, select, put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FlightsState, GetSuggestAirportPayload, SuggestedAirportsState } from 'Stores/flights/models';
import { RootState } from 'Stores/index';

function *getSuggestedAirportsHandler(action: PayloadAction<GetSuggestAirportPayload>) {
	const {provinceCode} = action.payload;

	const flightState = (yield select<(state: RootState) => FlightsState>(flightsSlice.selectors.get)) as FlightsState

	const currentSuggestedList = flightState.suggestedAirports;

	if(currentSuggestedList[provinceCode]) return;

	const { data, error } = yield call(FlightServiceV1.getAirportProvinceMapping.bind(FlightServiceV1), provinceCode);

	if(error) {
		return console.error(error);
	}

	yield put(flightsSlice.actions.suggestedAirportsActions.save({
		provinceCode,
		data
	}))
}

function *suggestedAirportsSaga() {
	yield takeEvery(flightsSlice.actions.suggestedAirportsActions.get, getSuggestedAirportsHandler)
}

export default [
	higherOrderSaga.createFetchedSaga(flightsSlice.actions.departureAirportsActions as any, FlightServiceV1.getDepartureAirports.bind(FlightServiceV1)),
	higherOrderSaga.createFetchedSaga(flightsSlice.actions.arrivalAirportsActions as any, FlightServiceV1.getArrivalAirports.bind(FlightServiceV1)),
	higherOrderSaga.createFetchedSaga(flightsSlice.actions.availableFlightsActions as any, FlightServiceV1.getAvailableFlights.bind(FlightServiceV1)),
	suggestedAirportsSaga
]