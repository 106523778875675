export enum ValidationMessageEnum {
	C2cNoValueWeight = 'c2c_notification_select_weight',
	C2cNoValueSize = 'c2c_notification_select_size',
	C2cSizeInvalid = 'c2c_notification_size_invalid',
	C2cNoChooseProduct = 'c2c_notification_select_product',
	C2cAddProductInvalid = 'c2c_notification_add_product',
	C2cNoChoosePaymentMethod = 'c2c_notification_select_payment_method',
	C2cPremiumKoreaNoSupport = 'leadtime_premium_product_not_support',
	C2cSupperKoreaNoSupport = 'leadtime_supper_korea_not_support',
	OrderBillFlightValid = 'order_bill_input_validation_select_flight',
	OrderBillSenderAddressValid = 'order_bill_input_validation_select_sender_address',
	OrderBillReceiverAddressValid = 'order_bill_input_validation_select_receiver_address',
	TermAndConditionAgreedValid = 'c2c_notification_agree_service',
	C2cGlobalAddressPCCC = 'c2c_notification_global_address_pccc',
}
