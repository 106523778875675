import { isEmpty } from 'lodash';
import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { DTOServiceCustomResponse } from 'Services/interfaces';
import { DTOUserProfile } from 'Services/v1/user/dto';
import { C2cOverPaymentState } from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import {
  C2cOrderPayment,
  C2cOrderQueryModel,
  C2cVatQueryMode,
  C2cVouchersQueryModel,
  GoodsInfoModel,
  PaymentCustom,
  PaymentData,
  PaymentKoreaParam
} from 'Stores/c2c-order-payment/models';
import { GrapState } from 'Stores/grap';
import { GrapSettingsEnums } from 'Stores/grap/enums';
import { OrderBillState } from 'Stores/order-bill/models';
import {
  Language,
  LANGUAGE_PERSIST_KEY,
  Location,
  TypePayment
} from 'Utilities/constants';

export const QueryBuilder = (
	c2cState: C2cOverPaymentState,
	productDeclaration?: Array<GoodsInfoModel>,
) => {
	const {
		deliveryMethodSelected,
		weightAndSize,
		productSelected,
		packageTypeSelected,
		typeOrder,
		voucherSelected,
		note,
		extraServiceSelected,
		extraServices,
		totalPrice,
		supperFee,
		selectReceiverPoint,
		selectSenderPoint,
		productTypes,
		isGlobal
	} = c2cState;


	const goodsDocument = {
		categories: productTypes?.document?.[0],
		goodsUrl: '',
		key: 'documents',
		material: 'Documents',
		name: 'Documents',
		price: '0',
		productId: 191,
		product_name: 'Documents',
		quantity: '1',
		receipts: '',
	};
	const goodsDeclaration =
		packageTypeSelected === C2cCategoryPackageTypeEnum.Document
			? [goodsDocument]
			: productDeclaration?.map((product) => {
					return {
						...product,
						key: product?.categories?.code,
						productId: product?.categories?.id,
						name: product?.productName,
					};
			  });

	const getVouchersQuery = (orderBillState: OrderBillState) => {
		const { senderAddress, receiverAddress } = orderBillState;
		return {
			productId: productSelected?.id,
			originId: [
				Number(senderAddress?.country),
				Number(senderAddress?.province),
				Number(senderAddress?.district),
				Number(senderAddress?.village),
			],
			destinationId: [
				Number(receiverAddress?.country),
				Number(receiverAddress?.province),
				Number(receiverAddress?.district),
				Number(receiverAddress?.village),
			],
		} as C2cVouchersQueryModel;
	};

	const getQueryVat = (
		orderBillState: OrderBillState,
		orderId: string,
	): C2cVatQueryMode => {
		const { vatInfo } = orderBillState;
		return {
			orderId: orderId,
			email: vatInfo?.data?.email,
			companyAddress: vatInfo?.data?.companyAddress,
			companyName: vatInfo?.data?.companyName,
			taxNumber: vatInfo?.data?.taxNumber,
		} as C2cVatQueryMode;
	};

	const getOrderQuery = (
		orderBillState: OrderBillState,
		grapState: GrapState,
	) => {
		const shippingTime = grapState?.settings?.find(
			(setting) => setting?.settingKey == GrapSettingsEnums.ShippingTime,
		)?.value;
		const toApollo = grapState?.settings?.find(
			(setting) => setting?.settingKey == GrapSettingsEnums.ToApollo,
		)?.value;
		const { senderAddress, receiverAddress, paymentMethod } =
			orderBillState;
		const finalExtraService = extraServices?.data?.map((extra) => {
			if (
				!extraServiceSelected.some((item) => item?.name === extra?.name)
			) {
				return { name: extra.name, price: '0' };
			}
			return extra;
		});

		const minEndTime = deliveryMethodSelected?.items?.[0]?.minEndTime || '';
		const beforeTime = deliveryMethodSelected?.items?.[0]?.before || '';
		const dateTimeSlot =
			deliveryMethodSelected?.items?.[0]?.receivedTimeSlots?.[0]?.date ||
			'';
		const timeSlotFrom =
			deliveryMethodSelected?.items?.[0]?.receivedTimeSlots?.[0]
				?.slots?.[0]?.time?.start || '';
		const timeSlotTo =
			deliveryMethodSelected?.items?.[0]?.receivedTimeSlots?.[0]
				?.slots?.[0]?.time?.end || '';
		let paymentMethodName: string | TypePayment = TypePayment.CASH;

		if (isGlobal && c2cState.chosenPaymentMethod?.codeParent) {
			paymentMethodName = c2cState.chosenPaymentMethod.codeParent;
		} else {
			paymentMethodName = paymentMethod?.bankType || paymentMethod?.code || '';
		}
		const orderDetails = isGlobal
			? goodsDeclaration
			: [
					{
						name: productSelected?.product,
						key: productSelected?.code,
						productId: productSelected?.id,
					},
			  ];
		const body = {
			senderAddress: String(senderAddress?.address),
			senderAdditionalInfo: senderAddress?.additionalInfo || null,
			receiverAddress: String(receiverAddress?.address),
			receiverAdditionalInfo: receiverAddress?.additionalInfo || null,
			fromLat: Number(senderAddress?.lat),
			fromLng: Number(senderAddress?.long),
			toLat: Number(receiverAddress?.lat),
			toLng: Number(receiverAddress?.long),
			originDop: deliveryMethodSelected?.originDop,
			destinationDop: deliveryMethodSelected?.destinationDop,
			discountAmount: deliveryMethodSelected?.discount,
			packageType: packageTypeSelected,
			deliveryType: deliveryMethodSelected?.deliveryType,
			amount: totalPrice,
			weight: weightAndSize?.weight,
			fromPhone: senderAddress?.phone,
			fromName: senderAddress?.personName,
			toPhone: receiverAddress?.phone,
			toName: receiverAddress?.personName,
			receiverAddressId: Number(receiverAddress?.id),
			senderAddressId: Number(senderAddress?.id),
			dimension: `${weightAndSize?.longs}x${weightAndSize?.width}x${weightAndSize?.height}`,
			chosenExtraPrice: extraServiceSelected,
			premium: typeOrder === TypeOrder.PREMIUM,
			super: typeOrder === TypeOrder.SUPER,
			location: isGlobal ? Location.KR : Location.VI,
			originId: [
				senderAddress?.country,
				senderAddress?.province,
				senderAddress?.district,
				senderAddress?.village,
			],
			destinationId: [
				receiverAddress?.country,
				receiverAddress?.province,
				receiverAddress?.district,
				receiverAddress?.village,
			],
			transportRules: [
				{
					rule: deliveryMethodSelected?.transportRule,
				},
			],
			note: note,
			hasInsurance: false,
			benAgreed: true,
			extraPrices: finalExtraService,
			voucher: voucherSelected?.code || '',
			superswiftFee: supperFee,
			beforeTime,
			dateTimeSlot,
			minEndTime,
			timeSlotFrom,
			timeSlotTo,
			destinationDropoffPoint: selectReceiverPoint?.code || '',
			orderDetails,
			originDropoffPoint: selectSenderPoint?.code || '',
			goodsInfo: isGlobal ? goodsDeclaration : [],
			senderPointInfo: isEmpty(selectSenderPoint)
				? []
				: [selectSenderPoint],
			receiverPointInfo: isEmpty(selectReceiverPoint)
				? []
				: [selectReceiverPoint],
			shippingTime, //grap api
			toApollo, //--grap
			device: 'browser',
			deviceType: 'browser',
			deviceToken: 'cargo_direct',
			ver: '1.8.2',
			paymentMethod: paymentMethodName,
			vnpBank: paymentMethod?.code,
		} as C2cOrderQueryModel;

    if (isGlobal) {
      body.vnpBank = c2cState.chosenPaymentMethod?.code;
    } else if (orderBillState.paymentMethod?.bankType === TypePayment.VNBANK ||
      orderBillState.paymentMethod?.bankType === TypePayment.INTCARD) {
        body.vnpBank = paymentMethod?.code;
    } else {
      delete body.vnpBank;
    }
		return body;
	};

	const mapBankList = (paymentData: PaymentData | undefined) => {
		return (paymentData?.card.detail || [])
        .map(bankItem => {
          return {
            card_code: bankItem?.cardCode,
            enabled: bankItem?.enabled
          };
        })
	};

	const getQueryPaymentMethod = (data: DTOServiceCustomResponse<C2cOrderPayment, PaymentCustom>, profile: DTOUserProfile) => {
		const paymentData = data?.meta?.custom?.paymentData;
		return {
			pg: paymentData?.pg || 'kcp',
			pay_method: paymentData?.payMethod || 'card',
			merchant_uid: paymentData?.merchantUid,
			popup: false,
			amount: paymentData?.amount,
			app_scheme: paymentData?.appScheme,
			buyer_tel: paymentData?.buyerTel,
			name: paymentData?.name || 'swift247', // required
			buyer_name: paymentData?.buyerName, // required
			buyer_email: paymentData?.buyerEmail, // required
			m_redirect_url: paymentData?.mRedirectUrl,
			niceMobileV2: paymentData?.niceMobileV2,
			escrow: paymentData?.escrow,
			language:
				localStorage.getItem(LANGUAGE_PERSIST_KEY) !== Language.KR
					? 'en'
					: 'ko',
			digital: paymentData?.payMethod === 'phone' ? true : false,
			card: {
				detail: [...mapBankList(paymentData)],
			},
		} as PaymentKoreaParam
	};

	return {
		getOrderQuery,
		getVouchersQuery,
		getQueryPaymentMethod,
		getQueryVat,
	};
};

export default QueryBuilder;
