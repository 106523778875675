import { ExtraServiceResponse } from '..';

export const EXTRA_SERVICE_INIT: ExtraServiceResponse = {
	data: [
		{
			name: 'price_packaging',
			price: '0',
		},
		{
			name: 'price_sms',
			price: '0',
		},
	],
};
