import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import { getSettingGrapAction } from 'Stores/grap/grap-reducer';
import localization from './localization';
import Layout from './pages/common/Layout';
import { RouteModel, RoutersMain } from './routes';
import {
  AddressRoutes,
  C2cOrderPaymentRoutes,
  OrderBillRoutes
} from './routes/constants';

const App = () => {
	localization.init();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSettingGrapAction());
	}, [])


	const renderRoutes = () => {
		const routes = [
			...RoutersMain,
			...AddressRoutes,
			...C2cOrderPaymentRoutes,
			...OrderBillRoutes,
		] as Array<RouteModel>;

		const routesWithSidebar = routes.filter((route) => route.sideBar);
		const routesWithoutSidebar = routes.filter((route) => !route.sideBar);

		const renderListRoutes = (routes: Array<RouteModel>) => {
			return routes.map((route, index) => (
				<Route key={index} path={route.path} element={route.component}>
					{route?.children ? renderListRoutes(route.children) : <></>}
				</Route>
			));
		};

		return (
			<Routes>
				<Route element={<Layout isShowSidebar={true} />}>
					{renderListRoutes(routesWithSidebar)}
				</Route>
				<Route element={<Layout isShowSidebar={false} />}>
					{renderListRoutes(routesWithoutSidebar)}
				</Route>
				<Route
					path="*"
					element={<Navigate to="/c2c-order-payment" replace />}
				/>
			</Routes>
		);
	};
	return <Router>{renderRoutes()}</Router>;
};

export default App;
