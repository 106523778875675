import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import koKR from 'antd/es/locale/ko_KR';
import viVN from 'antd/es/locale/vi_VN';
import CommonPopup from 'Components/CommonPopup/commonPopup';
import LayoutUtilities from 'Components/LayoutUtilities';
import moment from 'moment';
import 'moment/locale/ko';
import 'moment/locale/vi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { resetC2cOrderPayment } from 'Stores/c2c-order-payment';
import {
  layoutUtilitiesSliceSelectors,
  updateRoutePathAction
} from 'Stores/layout-utilities';
import { LanguageTypeEnum } from 'Stores/layout-utilities/enums';
import { resetOrderBill } from 'Stores/order-bill';
import { classPrefix } from 'Utilities/global';
import { SIDEBAR_LIST, TABS_HEADER } from '../Constants';
import TopNav from '../Header';
import Sidebar from '../Sidebar';
import './index.scss';

const Layout = (props: { isShowSidebar: boolean }) => {
	const { isShowSidebar } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPath = location?.pathname?.replace('/', '');
	const {popupCommon} = useSelector(layoutUtilitiesSliceSelectors.get);
  const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
  const [locale, setLocal] = useState(enUS);

	const handleOnChangeTab = (path: string) => {
		dispatch(resetOrderBill());
		dispatch(resetC2cOrderPayment(false));
		navigate(path);
	};

	useEffect(() => {
		dispatch(updateRoutePathAction(currentPath));
		// dispatch(updatePaymentMethod());
	}, [location.pathname]);

  useEffect(() => {
    let locale = enUS;
    let momentLocale = LanguageTypeEnum.English;
    if (currentLanguage === LanguageTypeEnum.VietNam) {
      locale = viVN;
      momentLocale = LanguageTypeEnum.VietNam;
    } else if (currentLanguage === LanguageTypeEnum.Korea) {
      locale = koKR;
      momentLocale = LanguageTypeEnum.Korea;
    }
    setLocal(locale);
    moment.locale(momentLocale);
  },
  [currentLanguage])

	return (
    <ConfigProvider locale={locale}>
		<LayoutUtilities>
			<TopNav
				tabs={TABS_HEADER}
				isShowTab={true}
				onChangeTab={handleOnChangeTab}
				defaultActiveKey={currentPath}
			/>
			<div className={`${classPrefix}-page-container d-flex`}>
				{isShowSidebar && <Sidebar items={SIDEBAR_LIST} />}
				<div
					className={`${classPrefix}-page-content ${
						isShowSidebar ? 'mt-8 mb-6' : ''
					}`}
				>
					<Outlet />
				</div>
			</div>
			<CommonPopup></CommonPopup>
		</LayoutUtilities>
    </ConfigProvider>
	);
};

export default Layout;
