import httpClient from '../../http-client';
import { DTOServiceResponse } from '../../interfaces';
import RootServiceV1 from '../root';
import { CamelSnakeMapper } from '../../data-mappers';
import {
	DTOAddress,
	DTOAddressRaw,
	DTOAddressPayload,
} from 'Services/v1/address-book/dto';

class AddressBookServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}
	async listAddress(
		payload: DTOAddressPayload,
	): Promise<DTOServiceResponse<DTOAddress[]>> {
		try {
			const response = await httpClient().post(
				`/v2/address?page=${payload?.page || 1}&limit=${payload?.limit || 20}`,
				{
					search_text: payload?.search_text,
					sort: [
						{
							field: 'default_sender',
							dir: 'desc',
						},
						{
							field: 'default_receiver',
							dir: 'desc',
						},
						{
							field: 'created_at',
							dir: 'desc',
						},
						// {
						// 	field: 'country',
						// 	dir: 'asc',
						// },
						// {
						// 	field: 'province',
						// 	dir: 'asc',
						// },
						// {
						// 	field: 'place_name',
						// 	dir: 'desc',
						// },
					],
				},
			);
			const data = response.data.data;
			const pagination = response.data?.meta?.pagination;

			return {
				statusCode: 200,
				data: data.map((item: DTOAddressRaw) =>
					CamelSnakeMapper.toCamelCase(item),
				),
				meta: {
					pagination: CamelSnakeMapper.toCamelCase(pagination),
				},
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async addAddress(
		payload: DTOAddress,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const requestData = CamelSnakeMapper.toSnakeCase<
				DTOAddress,
				DTOAddressRaw
				>(payload);

			const response = await httpClient().post('/v1/address', requestData);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async removeAddress(
		id: string | number,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const response = await httpClient().delete(`/v1/address/${id}`);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async updateAddress(
		id: string | number,
		payload: DTOAddress,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const requestData = CamelSnakeMapper.toSnakeCase<
				DTOAddress,
				DTOAddressRaw
				>(payload);

			const response = await httpClient().post(`/v1/address/${id}`, requestData);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async getAddress(
		id: string | number,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const response = await httpClient().get(`/v1/address/${id}`);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase<DTOAddressRaw, DTOAddress>(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async getAccessibleLocation() {
		try {
			const response = await httpClient().get(`/v1/address/configpicklatlon`);
			const data = response.data;
			return {
				statusCode: 200,
				data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
}

export default new AddressBookServiceV1() as AddressBookServiceV1;
