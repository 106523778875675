import productClassificationIcon from 'Assets/icon/product-size-and-weight-icon.svg';
import Section from 'Components/Section';
import { C2C_WEIGHT_MAX } from 'Pages/C2cOrderPayment/constants';
import {
  C2cCategoryPackageTypeEnum,
  C2cOverPaymentTextEnum
} from 'Pages/C2cOrderPayment/enums';
import { C2cSelectWeightConfigModel } from 'Pages/C2cOrderPayment/models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  onChangeWeightAction
} from 'Stores/c2c-order-payment';
import { ExtraService, SelectSize, SelectWeight } from '..';
import './styles.scss';

interface ProductSizeAndWeightProps {
	onAfterChange: () => void;
}

const ProductSizeAndWeight = (props: ProductSizeAndWeightProps) => {
	const { onAfterChange } = props;
	const { t } = useTranslation();
	const [weightConfig, setWeightConfig] = useState(
		{} as C2cSelectWeightConfigModel,
	);
	const {
		weightAndSize,
		c2cValidateFields,
		packageTypeSelected,
		toggleOrder,
		isGlobal,
		globalDirection
	} = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;
	const dispatch = useDispatch();

	const [_weight, setWeight] = useState(weightAndSize?.weight);
	useEffect(() => {
		setWeight(weightAndSize?.weight);
	}, [weightAndSize?.weight]);

	useEffect(() => {
		let weightCf = C2C_WEIGHT_MAX.ParcelKR;

		if (
			isGlobal &&
			packageTypeSelected === C2cCategoryPackageTypeEnum.Document
		) {
			weightCf = C2C_WEIGHT_MAX.DocumentKR;
		}
		if (
			!isGlobal &&
			packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel
		) {
			weightCf = C2C_WEIGHT_MAX.ParcelVN;
		}
		if (
			!isGlobal &&
			packageTypeSelected === C2cCategoryPackageTypeEnum.Document
		) {
			weightCf = C2C_WEIGHT_MAX.DocumentVN;
		}
		setWeightConfig(weightCf);
	}, [isGlobal, packageTypeSelected]);

	return (
		<Section
			className="c2c-product-size-and-weight"
			title={t(C2cOverPaymentTextEnum.ProductSizeAndWeight_Title)}
			icon={<img src={productClassificationIcon} alt={''} />}
		>
			<div className="c2c-size-weight-container row">
				<SelectWeight
					className="c2c-select-weight col-xl-6 col-12 pe-6"
					marks={weightConfig?.marks}
					tipFormatter={null}
					decimal={weightConfig?.decimal}
					max={weightConfig?.max}
					min={weightConfig?.min}
					value={_weight}
					validationField={{
						...c2cValidateFields.weight,
						toggle: toggleOrder,
					}}
					onChange={(value) => setWeight(value)}
					onAfterChange={(value) =>
						dispatch(onChangeWeightAction(value))
					}
				></SelectWeight>
				<SelectSize
					className="c2c-select-size ms-0 col-xl-6 col-12"
					onAfterChange={onAfterChange}
				></SelectSize>
			</div>
			{(!isGlobal || (isGlobal && globalDirection === 'outbound')) && (
				<ExtraService className="c2c-extraService"></ExtraService>
			)}
		</Section>
	);
};

export default ProductSizeAndWeight;
