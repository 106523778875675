import { Tooltip } from 'antd';
import dashedRouteIcon from 'Assets/icon/dashed-route-icon.svg';
import nextIcon from 'Assets/icon/next-icon.svg';
import pointAddressIcon from 'Assets/icon/point-address-icon.svg';
import postOfficeMapIcon from 'Assets/icon/post-office-map-icon.svg';
import { endsWith, startsWith } from 'lodash';
import { DeliveryTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import C2cOverPaymentServiceV1 from 'Services/v1/c2c-order-payment';
import {
	c2cOrderPaymentSelectors,
	selectReceiverPointAction,
	selectSenderPointAction,
} from 'Stores/c2c-order-payment';
import { orderBillSelectors } from 'Stores/order-bill';
import { DeliveryMethodEnum } from '../../enums';
import './styles.scss';

interface PropsModel {
	className?: string;
}

const DeliveryAddress = (props: PropsModel) => {
	const { className = '' } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { deliveryMethodSelected, selectReceiverPoint, selectSenderPoint } =
		useSelector(c2cOrderPaymentSelectors.get);

	const { senderAddress, receiverAddress } = useSelector(
		orderBillSelectors.get,
	);

	const getSenderName = useCallback(() => {
		switch (deliveryMethodSelected?.deliveryType) {
			case DeliveryTypeEnum.D2D:
			case DeliveryTypeEnum.D2P:
				return senderAddress?.personName;
			case DeliveryTypeEnum.P2D:
			case DeliveryTypeEnum.P2P:
				return selectSenderPoint?.name;
		}
	}, [deliveryMethodSelected, senderAddress, selectSenderPoint]);

	const getSenderAddress = useCallback(() => {
		switch (deliveryMethodSelected?.deliveryType) {
			case DeliveryTypeEnum.D2D:
			case DeliveryTypeEnum.D2P:
				return senderAddress?.address;
			case DeliveryTypeEnum.P2D:
			case DeliveryTypeEnum.P2P:
				return selectSenderPoint?.address;
		}
	}, [deliveryMethodSelected, senderAddress, selectSenderPoint]);

	const getReceiverName = useCallback(() => {
		switch (deliveryMethodSelected?.deliveryType) {
			case DeliveryTypeEnum.D2D:
			case DeliveryTypeEnum.P2D:
				return receiverAddress?.personName;
			case DeliveryTypeEnum.D2P:
			case DeliveryTypeEnum.P2P:
				return selectReceiverPoint?.name;
		}
	}, [deliveryMethodSelected, receiverAddress, selectReceiverPoint]);

	const getReceiverAddress = useCallback(() => {
		switch (deliveryMethodSelected?.deliveryType) {
			case DeliveryTypeEnum.D2D:
			case DeliveryTypeEnum.P2D:
				return receiverAddress?.address;
			case DeliveryTypeEnum.D2P:
			case DeliveryTypeEnum.P2P:
				return selectReceiverPoint?.address;
		}
	}, [deliveryMethodSelected, receiverAddress, selectReceiverPoint]);

	const isSenderPostOffice = startsWith(
		deliveryMethodSelected?.deliveryType,
		'P',
	);
	const isReceiverPostOffice = endsWith(
		deliveryMethodSelected?.deliveryType,
		'P',
	);

	// const openPopup = usePopup() as UsePopupModel;

	// const showPopupOffice = (type: string) => {
	//   const config = {
	//     ...CONFIRM_SELECT_PRODUCT_POPUP,
	//     title: t(ChoosePostOfficeTextEnum.ChoosePostOffice_Title),
	//     minWidth: '40vw',
	//     hideBtnCancel: true,
	//     hideBtnOk: true,
	//   };
	//   openPopup.onOpen(<CommonPopupLayout popupConfig={config}><ChoosePostOfficeContainer type={type}></ChoosePostOfficeContainer></CommonPopupLayout>, data => console.log(data))
	// }

	useEffect(() => {
		if (
			(selectSenderPoint && Object.keys(selectSenderPoint).length > 0) ||
			(selectReceiverPoint && Object.keys(selectReceiverPoint).length > 0)
		) {
			return;
		}
		if (!isReceiverPostOffice) {
			dispatch(selectReceiverPointAction({}));
		} else {
			// Find Post Office
			// Tìm địa chỉ bưu cựu của người nhận
			C2cOverPaymentServiceV1.getPostOffice(
				receiverAddress?.lat || '',
				receiverAddress?.long || '',
			).then((res) => {
				const dropPoints = res.data! || [];

				if (dropPoints.length > 0) {
					let item = dropPoints.find(
						(item) => item.id === deliveryMethodSelected?.originDop,
					);
					if (!item) item = dropPoints[0];
					const point = {
						id: item?.id,
						name: item?.name,
						address: item?.address,
						latitude: item?.geo?.coordinates[1],
						longitude: item?.geo?.coordinates[0],
						code: item?.code,
					};

					dispatch(selectReceiverPointAction(point));
				} else {
					dispatch(selectReceiverPointAction({}));
				}
			});
		}

		if (!isSenderPostOffice) {
			dispatch(selectSenderPointAction({}));
		} else {
			// Tìm địa chỉ bưu cục gần người gửi
			C2cOverPaymentServiceV1.getPostOffice(
				senderAddress?.lat || '',
				senderAddress?.long || '',
			).then((res) => {
				const dropPoints = res.data! || [];

				if (dropPoints.length > 0) {
					let item = dropPoints.find(
						(item) => item.id === deliveryMethodSelected?.originDop,
					);
					if (!item) item = dropPoints[0];
					const point = {
						id: item?.id,
						name: item?.name,
						address: item?.address,
						latitude: item?.geo?.coordinates[1],
						longitude: item?.geo?.coordinates[0],
						code: item?.code,
					};
					dispatch(selectSenderPointAction(point));
				} else {
					dispatch(selectSenderPointAction({}));
				}
			});
		}
	}, [
		senderAddress,
		receiverAddress,
		deliveryMethodSelected?.deliveryType,
		// isSenderPostOffice,
		// isReceiverPostOffice,
	]);

	return (
		<div className={`${className} delivery-address`}>
			<div className="delivery-address-icon">
				<img
					className="delivery-address-icon-point"
					src={pointAddressIcon}
					alt="atHome"
				></img>
				<img
					className="delivery-address-icon-dashed"
					src={dashedRouteIcon}
					alt="atHome"
				></img>
				<img
					className="delivery-address-icon-post-office"
					src={postOfficeMapIcon}
					alt="atHome"
				></img>
			</div>
			<div className="delivery-address-addresses">
				<div
					onClick={() => {
						isSenderPostOffice &&
							navigate('/choose-post-office', {
								state: {
									type: 'sender',
								},
							});
					}}
					className="delivery-address-departure"
				>
					<div>
						<div>
							{isSenderPostOffice && (
								<span>
									{t(
										DeliveryMethodEnum.DeliveryAddress_DeliveryAt,
									)}
									:{' '}
								</span>
							)}
							<span className="title-medium-15">
								{getSenderName()}
							</span>
						</div>
						<Tooltip
							placement="topLeft"
							overlayClassName="tooltip-address"
							overlayStyle={{ maxWidth: '500px' }}
							overlayInnerStyle={{ borderRadius: '5px' }}
							title={getSenderAddress()}
						>
							<div className="text-gray-primary dot">
								{getSenderAddress()}
							</div>
						</Tooltip>
					</div>
					{isSenderPostOffice && (
						<img className="" src={nextIcon} alt="next-icon"></img>
					)}
				</div>
				<div
					onClick={() => {
						isReceiverPostOffice &&
							navigate('/choose-post-office', {
								state: {
									type: 'receiver',
								},
							});
					}}
					className="delivery-address-destination divider-border-bottom"
				>
					<div>
						{isReceiverPostOffice && (
							<span>
								{t(
									DeliveryMethodEnum.DeliveryAddress_ReceiveAt,
								)}
								:{' '}
							</span>
						)}
						<span className="title-medium-15">
							{getReceiverName()}
							{/* <span className="text-gray-primary">{'930303030'}</span> */}
						</span>
						<Tooltip
							placement="topLeft"
							overlayClassName="tooltip-address"
							overlayStyle={{ maxWidth: '500px' }}
							overlayInnerStyle={{ borderRadius: '5px' }}
							title={getReceiverAddress()}
						>
							<div className="text-gray-primary dot">
								{getReceiverAddress()}
							</div>
						</Tooltip>
					</div>
					{isReceiverPostOffice && (
						<img className="" src={nextIcon} alt="next-icon"></img>
					)}
				</div>
			</div>
		</div>
	);
};

export default DeliveryAddress;
