import { FC, useState, useEffect } from 'react';

interface MarkerOptionLocal extends google.maps.MarkerOptions {
  dragendChange: (lat: number, lng: number) => void;
}

const Marker: FC<MarkerOptionLocal> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      google.maps.event.addListener(marker, 'dragend', (e: any) => {
        const { lat, lng } = e.latLng;
        if (lat() && lng()) {
          options.dragendChange(lat(), lng());
        }
      });
    }
  }, [marker, options]);

  return null;
};

export default Marker;
