import { Input } from 'antd';
import React, { CSSProperties, ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

type InputProps = React.HTMLProps<HTMLInputElement>;
interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'size' | 'type' | 'value'
  > {
  style?: CSSProperties | undefined;
  value?: any;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  className?: string;
  elementClassName?: string;
  icon?: ReactNode;
  type?: 'text' | 'number' | 'password';
  isDecimal?: boolean;
  isOnlyNumber?: boolean;
  error?: boolean;
  helperText?: string;
  errorMsg?: string;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onKeyUp?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onIconClick?: () => void;
  onWheel?: (e: any) => void;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  readonly?: boolean;
  addonBefore?: string | ReactNode;
  addonAfter?: string | ReactNode;
  status?: 'error' | 'warning' | '';
  suffix?: ReactNode;
  labelInfix?: string;
}
const InputText = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    style,
    value,
    onChange,
    name,
    className,
    elementClassName,
    icon,
    type,
    isOnlyNumber,
    isDecimal = false,
    error,
    helperText,
    onClear,
    onFocus,
    onBlur,
    disabled = false,
    errorMsg,
    label,
    placeholder,
    size = 'md',
    readonly,
    onIconClick,
    onWheel,
    onClick,
    onKeyUp,
    onKeyDown,
    addonBefore,
    addonAfter,
    status,
    suffix,
    labelInfix,
    ...rest
  } = props;

  const containerClasses = classnames(
    {
      [`${classPrefix}-input-text-container`]: true,
      [`${classPrefix}-input-text-container--error`]: error,
      [`${classPrefix}-input-text-container--disabled`]: disabled,
      [`${classPrefix}-input-text-container--${size}`]: true,
      [`${classPrefix}-input-text-container--with-label`]: label,
      [`${classPrefix}-input-text-container--with-label-infix`]: labelInfix
    },
    className
  );

  const handleKeyDown = (evt: any) => {
    if (isOnlyNumber) {
      if (evt.keyCode === 8 || evt.keyCode === 9 || evt.keyCode === 13 || evt.keyCode === 37 || evt.keyCode === 39 || evt.keyCode === 39 || evt.keyCode === 40) {
        return true;
      }
      if (evt.key === '.') {
        if (evt.currentTarget?.value.includes('.') || !isDecimal) {
          evt.preventDefault();
          return false;
        }
      }
      if (!/^[0-9.]+$/.test(evt.key)) {
        evt.preventDefault();
        return false;
      }
    }

    if (onKeyDown) onKeyDown(evt);
  };

  return (
    <>
      <div label-infix={labelInfix} className={`${containerClasses}`}>
        <Input
          style={style}
          placeholder={placeholder || label}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          value={value}
          onChange={onChange}
          onWheel={(e: any) => e.target.blur()}
          name={name}
          type={type}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onKeyDown={handleKeyDown}
          status={status}
          disabled={disabled}
          suffix={suffix}
          className={elementClassName}
          {...rest}
        />
      </div>
    </>
  );
});

export default InputText;
