import images from 'Assets/images';

export const PACKAGE_OVERALL_STATUS = {
  SENDING: 'SENDING',
  RECEIVING: 'RECEIVING',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED'
};

export const PACKAGE_JOURNEY_STATUS = {
  NOTIFY_RECEIVED: 'received',
  NOTIFY_IN_TRANSIT_RECEIVED_HUB: 'in_transit_received_hub',
  NOTIFY_STATIONED_IN: 'stationed_in',
  NOTIFY_STATION_IN: 'station_in',
  NOTIFY_HANDOVER_RACK_ASSIGNED: 'handover_rack_assigned',
  NOTIFY_DELIVERED: 'delivered',
  NOTIFY_SECURITY_FAILED: 'security_failed',
  DESTINATION_WITH_STATUS_STATION_IN: 'VJ_OFC_TS',
  DESTINATION_IS_HCM: 'VJ_OFC_HCR',
  // General
  PICKING_UP: 'picking_up',
  PICKUP_SUCCESS: 'pickup_success',
  PICKUP_FAILED: 'pickup_failed',
  DROPOFF_SUCCESS: 'dropoff_success',
  DROPOFF_FAILED: 'dropoff_failed',
  IN_TRANSIT: 'in_transit',
  READY_TO_SHIP: 'ready_to_ship',
  RECEIVED_HUB: 'received_hub',
  IN_TRANSIT_RECEIVED_HUB: 'in_transit_received_hub',
  IN_TRANSIT_DELIVERED_HUB: 'in_transit_delivered_hub',
  ATTEMPT: 'attempt',
  ATTEMPT_FAILED: 'attempt_failed',
  DELIVERED: 'delivered',
  DELIVERY_FAILED: 'delivery_failed',
  RETURNING: 'returning',
  RETURNED: 'returned',
  CANCELLED: 'cancelled',
  CANCELLED_BY_3PL: 'cancelled_by_3pl',
  MISSING: 'missing',
  LOST: 'lost',
  FOUND: 'found',
  DAMAGED: 'damaged',

  // P2P Shipping Provider
  PICKUP_REQUEST_SENT: 'pickup_request_sent',
  ALLOCATING_DRIVER: 'allocating_driver',
  DRIVER_ASSIGNED: 'driver_assigned',
  DRIVER_ALLOCATION_FAILED: 'driver_allocation_failed',

  // Line Haul
  LH_HUB_INBOUNDED: 'lh_hub_inbounded',
  LH_HUB_OUTBOUNDED: 'lh_hub_outbounded',
  LH_SECURITY_PASSED: 'lh_security_passed',
  LH_SECURITY_FAILED: 'lh_security_failed',
  LH_FLIGHT_ASSIGNED: 'lh_flight_assigned',
  LH_FLIGHT_TAKEN_OFF: 'lh_flight_taken_off',
  LH_FLIGHT_LANDED: 'lh_flight_landed',
  LH_IN_CUSTOM: 'lh_in_custom',
  LH_ARRIVED: 'lh_arrived',
  LH_HANDED_OVER: 'lh_handed_over',
  LH_DELIVERY_FAILED: 'lh_delivery_failed',
  LH_PACKAGE_RETURNED: 'lh_package_returned',
  LH_WAITING_FOR_PICKUP: 'lh_waiting_for_pickup',

  // Amelia Line Haul
  LH_CONSIGNMENT_AMENDED: 'lh_consignment_amended',
  LH_CONSIGNMENT_OFFLOADED: 'lh_consignment_offload',

  // Collection Point
  CP_PARCEL_READY: 'cp_parcel_ready',
  CP_CUSTOMER_PICKUP_SUCCESS: 'cp_customer_pickup_success',
  CP_CUSTOMER_FAILED_TO_PICKUP: 'cp_customer_failed_to_pickup',
  CP_PACKAGE_RETURNED: 'cp_package_returned',

  // COD
  ASSIGNED_FM: 'cod_assigned_fm',
  PROCESSING_FM: 'cod_processing_fm',
  CANCEL_REASSIGN_FM: 'cod_cancel_reassign_fm',
  CANCEL_REASSIGN_LM: 'cod_cancel_reassign_lm',
  RECEIVING_FM: 'cod_receiving_fm',
  DRIVER_FOR_CONTROL_FM: 'cod_driver_for_control_fm',
  OPS_FOR_CONTROL_FM: 'cod_ops_for_control_fm',
  ASSIGNED_LM: 'cod_assigned_lm',
  FAIL_COLLECTION_FM: 'cod_fail_collection_fm',
  IN_STOCK_LM: 'cod_in_stock_lm',
  RETURNED_LM: 'cod_returned_lm',
  PROCESSING_LM: 'cod_processing_lm',
  FAIL_SHIPPING_LM: 'cod_fail_shipping_lm',
  DRIVER_FOR_CONTROL_FAIL_LM: 'cod_driver_for_control_fail_lm',
  OPS_FOR_CONTROL_LM: 'cod_ops_for_control_lm',
  FINANCE_FOR_CONTROL_LM: 'cod_finance_for_control_lm',
  DRIVER_FOR_CONTROL_SUCCESS_LM: 'cod_driver_for_control_success_lm',
  ORDER_SUCCESS_LM: 'cod_order_success_lm',
  PROCCESSING_LM: 'proccessing_lm',

  // GHN
  CANCELED_BY_3PL: 'canceled_by_3pl',
  DELIVERING: 'delivering',
  RETURN_FAILED: 'return_failed',

  //Lalamove
  LLM_REJECTED: 'rejected',
  LLM_EXPIRED: 'expired'
};

export const DATE_FORMATS = {
  ISO_8601: "yyyy-MM-dd'T'HH:mm:ssxxx",
  ISO_CUSTOM: 'yyyy-MM-dd HH:mm:ssxxx',
  ISO_CUSTOM_SHORT: 'yyyy-MM-dd HH:mm:ss',
  DDMMYYYY: 'DD/MM/YYYY',
  YYYYMMDD: 'YYYY-MM-DD',
  DDMMYYYYHHmm: 'DD/MM/YYYY HH:mm',
  ISO_TIMEZONE: 'YYYY-MM-DD HH:mm:ssZ'
};

export const ALLOWED_CANCEL_TIME = 8; //hours

export enum PROFILE_TYPES {
  business = 'B',
  personal = 'P'
}

export const TIME_ZONE = '+07';

export enum FLIGHT_LOAD_STATUS {
  LOAD_ENOUGH = 'flight_load_status_enough',
  LOAD_LOW = 'flight_load_status_low',
  LOAD_FULL = 'flight_load_status_full',
  LOAD_EXCEEDED = 'flight_load_status_exceeded'
}

export const SUPPORTED_LANGUAGE = [
  {
    name: 'Việt Nam',
    key: 'vi',
    icon: images.viFlagSquareUrl
  },
  {
    name: 'English',
    key: 'en',
    icon: images.enFlagSquareUrl
  },
  {
    name: '한국어',
    key: 'kr',
    icon: images.krFlagSquareUrl
  }
];

export enum TypeCurrency {
  VND = 'đ',
  WON = '₩',
  DOLLAR = '$'
}

export enum VOUCHER_STATUS {
  INVALID = 'voucher_status_invalid',
  OUTDATED = 'voucher_status_outdated',
  REACH_LIMIT = 'voucher_status_reach_limit',
  VALID = 'voucher_status_valid',
  NOT_START = 'voucher_status_not_start',
  REACH_LIMIT_DAILY = 'voucher_status_reach_limit_daily',
  REACH_LIMIT_CUSTOMER = 'voucher_status_reach_limit_customer'
}

export type DTOVoucherStatus =
  | VOUCHER_STATUS.INVALID
  | VOUCHER_STATUS.OUTDATED
  | VOUCHER_STATUS.REACH_LIMIT
  | VOUCHER_STATUS.VALID
  | VOUCHER_STATUS.NOT_START
  | VOUCHER_STATUS.REACH_LIMIT_DAILY
  | VOUCHER_STATUS.REACH_LIMIT_CUSTOMER;

export interface DTOVoucherStatusMapping {
  [key: string]: VOUCHER_STATUS;
}

export const VOUCHER_STATUS_MAPPING: DTOVoucherStatusMapping = {
  VOUCHER_INCORRECT: VOUCHER_STATUS.INVALID,
  VOUCHER_OUTDATED: VOUCHER_STATUS.OUTDATED,
  VOUCHER_REACH_LIMIT: VOUCHER_STATUS.REACH_LIMIT,
  VOUCHER_GOOD_GOOD: VOUCHER_STATUS.VALID,
  VOUCHER_NOT_START: VOUCHER_STATUS.NOT_START,
  VOUCHER_REACH_LIMIT_DAILY: VOUCHER_STATUS.REACH_LIMIT_DAILY,
  VOUCHER_REACH_LIMIT_CUSTOMER: VOUCHER_STATUS.REACH_LIMIT_CUSTOMER
};

export enum TypeCard {
  PHONE = 'phone',
  CARD = 'card',
  SAMSUNG = 'samsung',
  VBANK = 'vbank',
  PAYCO = 'payco',
  CASH = 'cash',
  VN_PAY = 'vn-pay'
}

export enum TypePayment {
  VNBANK = 'VNBANK',
  INTCARD = 'INTCARD',
  CASH = 'Cash',
  VNPAY = 'VNPAY',
  KCP = 'kcp',
  EXIM_BAY = 'eximbay',
  TOPUP = 'TOPUP'
}

export enum BOOKING_TYPE {
  C2C = 'C2C',
  CargoDirect = 'CD_Swift247'
}
export const koreaProvinceCode = 'KOREA';
export const C2C_ORDER_PAYMENT_PATH = 'c2c-order-payment';
export const LANGUAGE_PERSIST_KEY = '_app_locale_key';
export type LANGUAGE_KEY = 'vi' | 'en' | 'kr';
export const KOREA_COUNTRY_CODE = '14326';
export const VIETNAM_COUNTRY_CODE = '0';

export const PAGESIZE = 20;
export const PAGESIZE_UNLIMIT = 5000;
export const TIMER_SEARCH = 500;
export enum Language {
  VI = 'vi',
  EN = 'en',
  KR = 'kr'
}
export enum Location {
  VI = 'vi',
  KR = 'kr'
}

export const PHONE_SERVICE = {
  [Language.VI]: '1900272727',
  [Language.KR]: '1533 1119',
  [Language.EN]: '1900272727'
};

export enum PHONE_CODE_NUMBER {
  VIETNAM = '84',
  KOREA = '82'
}
export const PHONES_CODE = [
  {
    value: PHONE_CODE_NUMBER.VIETNAM,
    label: '+84',
    icon: images.viFlagSquareUrl
  },
  {
    value: PHONE_CODE_NUMBER.KOREA,
    label: '+82',
    icon: images.krFlagSquareUrl
  }
];

export const COMMODITY_DESCRIPTION = ['0000', '0001', '9999', '9940', '9950'];

export const DIMENSIONS_DEFAULT = [
  {
    long: null,
    width: null,
    height: null,
    quantity: 1
  }
];
export const DEFAULT_DAY_LEAD_TIME_ORDER = 4;

export const REGREX_UTIL = {
  atLeastOneSpecialCharacter: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  spacesStartOrEnd: /^[^\s]+(\s+[^\s]+)*$/,
  onlyCharsAndNums: /^[a-zA-Z0-9]*$/
};

export const TIME_CANCEL_ORDER_CARGO_DIRECT = 8;

export enum STATUS_ORDER {
  IN_PROGRESS = 'IN_PROGRESS',
  SENDING = 'SENDING',
  RECEIVING = 'RECEIVING',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  DONE = 'DONE'
}

export const TRANSPORT_RULE_GLOBAL = [
  'VIETNAM_TO_KOREA',
  'KOREA_TO_VIETNAM',
  'SEOUL_TO_VIETNAM'
];

export const SWIFT247_HOTLINE = '0968 600 247';
